import React, { useState, useEffect } from 'react';

function CertificateForm({ onNext, onPrevious, certificate }) {
  // Retrieve data from session storage or initialize with default data
  const initialCertificates = JSON.parse(sessionStorage.getItem('certificates')) || [{ name: '', description: '' }];

  const [certificates, setCertificates] = useState(certificate || initialCertificates);

  useEffect(() => {
    sessionStorage.setItem('certificates', JSON.stringify(certificates)); // Store data in session storage
  }, [certificates]);

  const handleAddCertificate = () => {
    setCertificates([...certificates, { name: '', description: '' }]);
  };

  const handleRemoveCertificate = (index) => {
    const updatedCertificates = [...certificates];
    updatedCertificates.splice(index, 1);
    setCertificates(updatedCertificates);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedCertificates = [...certificates];
    updatedCertificates[index][name] = value;
    setCertificates(updatedCertificates);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(certificates);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
      <h2 className='headingName'>Certification Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {certificates.map((certificate, index) => (
          <div key={index}>
            <div className="input-group">
              <label htmlFor={`certificateName${index}`}>Certificate Name:</label>
              <input
                type="text"
                id={`certificateName${index}`}
                name="name"
                value={certificate.name}
                onChange={(e) => handleInputChange(index, e)}
                placeholder='Enter name of the Certificate'
                autoComplete='certification'
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`certificateDescription${index}`}>Description:</label>
              <textarea
                id={`certificateDescription${index}`}
                name="description"
                value={certificate.description}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Describe your certificate upto 150 words"
                maxLength={150}
                required
              />
            </div>
              <button type="button" onClick={() => handleRemoveCertificate(index)} className='removeButton'>Remove</button>
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddCertificate}>Add Certificate</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default CertificateForm;
