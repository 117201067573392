import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from './SiteComponent/Home/Home';
import Resume from './SiteComponent/Resume/Resume';
import Contact from './SiteComponent/Contact/Contact';
import Navbar from "./SiteComponent/Navbar";
import Patent from "./SiteComponent/Patent/Patent";
import Brand from "./SiteComponent/Branding/Brand";
import "./App.css";
import Finance from "./SiteComponent/Finance/Finance";
import SipCalculator from "./Calculator/SipCalculator";
import Internship from "./SiteComponent/Internship/Internship";
import Website from "./SiteComponent/Website/Website";
import Footer from "./SiteComponent/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AiTools from "./SiteComponent/AITools/AiTools";
import SocialAi from "./SiteComponent/SocialAi/SocialAi";
import Occassional from "./SocialComponent/Occasional/Occasional";
import Diwali from "./SocialComponent/Occasional/Diwali/Diwali";
import RakshaBhandan from "./SocialComponent/Occasional/RakshaBhandan/RakshaBhandan";
import IndependenceDay from "./SocialComponent/Occasional/IndependenceDay/Independence";
import GaneshChathurthi from "./SocialComponent/Occasional/GaneshChaturthi/Ganesh";
import Login from "./Registration/login";
import SignUpForm from "./Registration/registration";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from './Registration/AuthContext';
import GymPost from "./SocialBuisnessComponent/BuisnessAi/GymPost";
import BusinessPage from "./SocialBuisnessComponent/BuisnessAi/BusinessPage";
import EducationPost from "./SocialBuisnessComponent/BuisnessAi/EducationPost";
import OtherPost from "./SocialBuisnessComponent/BuisnessAi/OtherPost";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <AuthProvider>
        <div className="main-app-container">
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/aiTools" element={<AiTools />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/patent" element={<Patent />} />
            <Route path="/brand" element={<Brand />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/calculator" element={<SipCalculator />} />
            <Route path="/internship" element={<Internship />} />
            <Route path="/website" element={<Website />} />
            <Route path="/socialAi" element={<SocialAi />} />
            <Route path="/occasional" element={<Occassional />} />
            <Route path="/buisness" element={<BusinessPage />} />
            <Route path="/diwali" element={<Diwali />} />
            <Route path="/rakshaBhandan" element={<RakshaBhandan />} />
            <Route path="/independence" element={<IndependenceDay />} />
            <Route path="/ganeshChaturthi" element={<GaneshChathurthi />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUpForm />} />
            <Route path="/gym" element={<GymPost />} />
            <Route path="/education" element={<EducationPost />} />
            <Route path="/other" element={<OtherPost />} />
          </Routes>
          <ToastContainer />
        </div>
        <Footer />
    </AuthProvider>
  );
}

export default App;
