import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import "./GymTemplate1.css";

const GymTemplate1 = () => {
  const [formData] = useState({
    businessData: {
      tagline: sessionStorage.getItem('tagline') || 'Shape Your Perfect Body',
      businessName: sessionStorage.getItem('businessName'),
      contactNumber: sessionStorage.getItem('contactNumber'),
      offerPercentage: sessionStorage.getItem('offerPercentage'),
    },
    logo: sessionStorage.getItem('logo')
  });

  useEffect(() => {
    // Scroll to the bottom to capture the entire content
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const downloadImage = async () => {
    const element = document.getElementById('gym-details');

    const scale = 3; // Adjust scale for high-DPI screens
    const canvas = await html2canvas(element, { scale: scale });

    // Create a new canvas with the desired dimensions
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = 1080;
    resizedCanvas.height = 1080;
    const context = resizedCanvas.getContext('2d');

    // Draw the original canvas onto the resized canvas, scaling it down
    context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 1080, 1080);

    const data = resizedCanvas.toDataURL('image/jpeg', 1.0);
    const link = document.createElement('a');

    link.href = data;
    link.download = 'downloaded-image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='r-container'>
      <div className='gym-details' id='gym-details'>
        <div className='gym-part1'>
          <div className='tagline'>{formData.businessData.tagline}</div>
          <div className='offer-percent'>Get up to {formData.businessData.offerPercentage}% off</div>
          <div className='join'>JOIN NOW</div>
          <div className='gym-contact'>
            <div>Contact Us</div>
            <div>{formData.businessData.contactNumber}</div>
          </div>
        </div>
      </div>
      <div><button onClick={downloadImage} className='r-download'>Download Image</button></div>
    </div>
  );
};

export default GymTemplate1;
