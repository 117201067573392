import React, { useState, useEffect } from 'react';
import BusinessForm from '../BuisnessForm/BuisnessForm';
import GymTemplate1 from '../BuisnessTemplate/GymRelated/GymTemplate1';
import GymTemplate2 from '../BuisnessTemplate/GymRelated/GymTemplate2';

const GymPost = () => {
  const [isGymFormVisible, setIsGymFormVisible] = useState(true);

  const handleNext = () => {
    setIsGymFormVisible(false);
  };

  useEffect(() => {
    if (!isGymFormVisible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  }, [isGymFormVisible]);

  const templates = [
    { id: 1, component: <GymTemplate1 /> },
    { id: 2, component: <GymTemplate2 /> }
  ];

  return (
    <div>
      {isGymFormVisible ? (
        <BusinessForm onNext={handleNext} />
      ) : (
        <div className='templates-container'>
          <h2 className='template-header'>Select a Template</h2>
          <div className='templates-alignment'>
            {templates.map((template) => (
              <div key={template.id} className='template-wrapper'>
                <div>
                  {template.component}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GymPost;
