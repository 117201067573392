import React, { useState, useEffect } from 'react';
import "./WorkExperienceForm.css";

function WorkExperienceForm({ onNext, onPrevious }) {
  // Retrieve data from session storage or initialize with default data
  const initialFormData = JSON.parse(sessionStorage.getItem('workExperiences')) || [{ id: 1, companyName: '', position: '', startDate: '', endDate: '', jobDescription: '', additionalField1: '', additionalField2: '', location: '' }];

  const [formData, setFormData] = useState(initialFormData);
  const [nextId, setNextId] = useState(2); // Initial ID for next work experience entry

  useEffect(() => {
    sessionStorage.setItem('workExperiences', JSON.stringify(formData)); // Store data in session storage
  }, [formData]);

  const handleInputChange = (id, event) => {
    const { name, value } = event.target;
    const newExperiences = formData.map(experience => experience.id === id ? { ...experience, [name]: value } : experience);
    setFormData(newExperiences);
  };

  const handleAddExperience = () => {
    setFormData([...formData, { id: nextId, companyName: '', position: '', startDate: '', endDate: '', jobDescription: '', additionalField1: '', additionalField2: '', location: '' }]);
    setNextId(nextId + 1);
  };

  const handleRemoveExperience = (id) => {
    const updatedExperiences = formData.filter(experience => experience.id !== id);
    setFormData(updatedExperiences);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(formData);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Work Experience</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {formData.map((experience, index) => (
          <div key={experience.id}>
            <div className="input-group">
              <label htmlFor={`companyName${experience.id}`}>Company Name:</label>
              <input
                type="text"
                id={`companyName${experience.id}`}
                name="companyName"
                value={experience.companyName}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Enter your Organization Name"
                autoComplete="organization"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`position${experience.id}`}>Position:</label>
              <input
                type="text"
                id={`position${experience.id}`}
                name="position"
                value={experience.position}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Enter your position [Ex:- Frontend Developer Intern]"
                autoComplete="job-title"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`location${experience.id}`}>Location:</label>
              <input
                type="text"
                id={`location${experience.id}`}
                name="location"
                value={experience.location}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Enter your Job location [Noida/Remote]"
                autoComplete="work-location"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`startDate${experience.id}`}>Start Date:</label>
              <input
                type="text"
                id={`startDate${experience.id}`}
                name="startDate"
                value={experience.startDate}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Month Year (e.g., February 2023)"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`endDate${experience.id}`}>End Date:</label>
              <input
                type="text"
                id={`endDate${experience.id}`}
                name="endDate"
                value={experience.endDate}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Month Year (e.g., September 2024)"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`jobDescription${experience.id}`}>Job Description:</label>
              <input
                type="text"
                id={`jobDescription${experience.id}`}
                name="jobDescription"
                value={experience.jobDescription}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Description point 1"
                required
                maxLength={150}
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                id={`additionalField1${experience.id}`}
                name="additionalField1"
                value={experience.additionalField1}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Description point 2 if needed"
                maxLength={150}
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                id={`additionalField2${experience.id}`}
                name="additionalField2"
                value={experience.additionalField2}
                onChange={(e) => handleInputChange(experience.id, e)}
                placeholder="Description point 3 if needed"
                maxLength={150}
              />
            </div>
            <button type="button" onClick={() => handleRemoveExperience(experience.id)} className="removeButton">Remove</button>
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddExperience}>Add Experience</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default WorkExperienceForm;
