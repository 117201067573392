import React from 'react';
import googleLogo from './AssetLogin/GoogleRegister.jpeg'; // Adjust the path as necessary
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db } from './firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setDoc, doc } from "firebase/firestore";

function SignInWithGoogle({ onClose}) {
    const googleLogin = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then(async (result) => {
                const user = result.user;
                if (user) {
                    await setDoc(doc(db, "Users", user.uid), {
                        email: user.email,
                        userName: user.displayName,
                        photo: user.photoURL,
                    });
                    toast.success("User logged in Successfully!!", {
                        position: "top-center",
                    });
                    onClose();
                }
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: "top-center",
                });
            });
    };

    return (
        <div>
            <p className="continue-p">--Or Continue with--</p>
            <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={googleLogin}>
                <img src={googleLogo} alt="Google Sign In" style={{ width: "50%" }} />
            </div>
        </div>
    );
}

export default SignInWithGoogle;
