import React, { useState } from "react";
import PersonalInfoForm from "../Components/PersonalInfoForm";
import WorkExperienceForm from "../Components/WorkExperienceForm";
import EducationForm from "../Components/EducationForm";
import ProjectForm from "../Components/ProjectForm";
import ProfessionalSkills from "../Components/ProfessionalSkills";
import LanguageForm from "../Components/LanguageForm";
import InterestForm from "../Components/InterestForm";
import ProfilePhotoUpload from "../Components/ProfilePhotoUpload";
import { FaPhone } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoRemoveOutline } from "react-icons/io5";
import "./Template2.css";

function Template2() {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [education, setEducation] = useState(null);
  const [projects, setProjects] = useState([]);
  const [professionalSkills, setProfessionalSkills] = useState([]);
  const [language, setLanguage] = useState([]);
  const [interest, setInterest] = useState([]);
  const [showWorkExperienceForm, setShowWorkExperienceForm] = useState(false);
  const [showEducationForm, setShowEducationForm] = useState(false);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showProfessionalSkills, setShowProfessionalSkills] = useState(false);
  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [showInterestForm, setShowInterestForm] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showProfilePhoto, setShowProfilePhoto] = useState(false);

  const handlePersonalInfoNext = (data) => {
    setPersonalInfo(data);
    setShowWorkExperienceForm(true);
  };

  const handleWorkExperienceNext = (data) => {
    setWorkExperiences(data);
    setShowWorkExperienceForm(false);
    setShowEducationForm(true);
  };

  const handleEducationNext = (data) => {
    setEducation(data);
    setShowEducationForm(false);
    setShowProjectForm(true);
  };

  const handleProjectNext = (data) => {
    setProjects(data);
    setShowProjectForm(false);
    setShowProfessionalSkills(true);
  };

  const handleProfessionalSkillsNext = (data) => {
    setProfessionalSkills(data);
    setShowProfessionalSkills(false);
    setShowLanguageForm(true);
  };

  const handleLanguageNext = (data) => {
    setLanguage(data);
    setShowLanguageForm(false);
    setShowInterestForm(true);
  };
  const handleInterestNext = (data) => {
    setInterest(data);
    setShowProfilePhoto(true);
  };
  const handleProfileNext = (data) => {
    setProfilePhoto(data);
    setShowTemplate(true);
  };

  const handleWorkExperiencePrevious = () => {
    setShowWorkExperienceForm(false);
  };

  const handleEducationPrevious = () => {
    setShowWorkExperienceForm(true);
    setShowEducationForm(false);
  };

  const handleProjectPrevious = () => {
    setShowEducationForm(true);
    setShowProjectForm(false);
  };

  const handleProfessionalSkillsPrevious = () => {
    setShowProjectForm(true);
    setShowProfessionalSkills(false);
  };

  const handleLanguagePrevious = () => {
    setShowProfessionalSkills(true);
    setShowLanguageForm(false);
  };

  const handleInterestPrevious = () => {
    setShowLanguageForm(true);
    setShowInterestForm(false);
  };
  const handlePhotoPrevious = () => {
    setShowInterestForm(true);
    setShowProfilePhoto(false);
  };

  const hideButtonAndPrint = () => {
    var button = document.getElementById("downloadButton");
    button.classList.add("hidden");
    window.print();
  };

  return (
    <div>
      {!showWorkExperienceForm && !showEducationForm && !showProjectForm && !showProfessionalSkills && !showLanguageForm && !showInterestForm && !showTemplate && !showProfilePhoto && (
        <div>
          <PersonalInfoForm onNext={handlePersonalInfoNext} />
        </div>
      )}
      {showWorkExperienceForm && !showEducationForm && !showProjectForm && !showProfessionalSkills && !showLanguageForm && !showInterestForm && !showTemplate && !showProfilePhoto && (
        <div>
          <WorkExperienceForm onNext={handleWorkExperienceNext} onPrevious={handleWorkExperiencePrevious} />
        </div>
      )}
      {showEducationForm && !showProjectForm && !showProfessionalSkills && !showLanguageForm && !showInterestForm && !showTemplate && !showProfilePhoto && (
        <div>
          <EducationForm onNext={handleEducationNext} onPrevious={handleEducationPrevious} />
        </div>
      )}
      {showProjectForm && !showProfessionalSkills && !showLanguageForm && !showInterestForm && !showTemplate && !showProfilePhoto && (
        <div>
          <ProjectForm onNext={handleProjectNext} onPrevious={handleProjectPrevious} />
        </div>
      )}
      {showProfessionalSkills && !showLanguageForm && !showInterestForm && !showTemplate && !showProfilePhoto && (
        <div>
          <ProfessionalSkills onNext={handleProfessionalSkillsNext} onPrevious={handleProfessionalSkillsPrevious} />
        </div>
      )}
      {showLanguageForm && !showInterestForm && !showTemplate && !showProfilePhoto && (
        <div>
          <LanguageForm onNext={handleLanguageNext} onPrevious={handleLanguagePrevious} />
        </div>
      )}
      {showInterestForm && !showProfilePhoto && !showTemplate && (
        <div>
          <InterestForm onNext={handleInterestNext} onPrevious={handleInterestPrevious} />
        </div>
      )}
      {showProfilePhoto && !showTemplate && (
        <div>
          <ProfilePhotoUpload onNext={handleProfileNext} onPrevious={handlePhotoPrevious} />
        </div>
      )}

      <div>
        {showTemplate && (
          <div>
            <div style={{paddingLeft: '30px'}}>
              <button id="downloadButton" onClick={hideButtonAndPrint}>DOWNLOAD</button>
            </div>
            <div className="container">
              <div className="left_side">
                <div className="profileText">
                  {profilePhoto && (
                    <div className="imgProfile2">
                      <img src={URL.createObjectURL(profilePhoto)} alt="Profile" className="imgImg" />
                    </div>
                  )}
                  {personalInfo && (
                    <div className="Full-Name">
                      {personalInfo.fullName} <br />
                      <span className="titlePersonal">{personalInfo.title}</span>
                    </div>
                  )}
                </div>
                <div className="contact-info">
                  <div className="title">Contact Info</div>
                  {personalInfo && (
                    <div className="contact-details">
                      {personalInfo.phone && <div className="list-contact"><span className="icon"><FaPhone aria-hidden="true" /></span><span className="text">{personalInfo.phone}</span></div>}
                      {personalInfo.email && <div className="list-contact"><span className="icon"><MdMail aria-hidden="true" /></span><span className="text">{personalInfo.email}</span></div>}
                      {personalInfo.github && <div className="list-contact"><span className="icon"><FaGithub aria-hidden="true" /></span><span className="text">{personalInfo.github}</span></div>}
                      {personalInfo.linkedIn && <div className="list-contact"><span className="icon"><FaLinkedin aria-hidden="true" /></span><span className="text">{personalInfo.linkedIn}</span></div>}
                      {personalInfo.location && <div className="list-contact"><span className="icon"><FaMapMarkerAlt aria-hidden="true" /></span><span className="text">{personalInfo.location}</span></div>}
                    </div>
                  )}
                </div>
                <div className="contact-info education">
                  <div className="title">Education</div>
                  {education.map((education, index) => (
                    <div key={index}>
                      <div className="educationDetails">
                        <div className="startCGPA">
                          <div className="dateEducation">{education.startDate}{"-"}{education.endDate}</div>
                          {education.cgpaPercent && <div className="cgpaPercentt">{education.cgpaPercent}{" CGPA"}</div>}
                        </div>
                        <div className="courseStream">{education.courseName}{" in "}{education.stream}</div>
                        <div className="collegeEducation">{education.collegeName}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="contact-info languageLanguage">
                  <div className="title">Languages</div>
                  {language.length > 0 && (
                    <div className="languagePercent">
                      {language.map((language, index) => (
                        <div key={index}>
                          <div>
                            <span className="text">{language.name}</span>
                            <span className="percent"><div className="widthStyle" style={{ width: `${language.fluency * 10}%` }}></div></span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="contact-info interestInterest">
                  <div className="title">Interest</div>
                  {interest.length > 0 && (
                    <div className="interestAlign">
                      {interest.map((interest, index) => (
                        <div key={index}>
                          <div>{interest.name}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="right_side">
                <div className="about">
                  <div className="title2">Summary</div>
                  {personalInfo && (
                    <div className="summaryDescription">{personalInfo.description}</div>
                  )}
                </div>
                <div className="about">
                  {workExperiences.length > 0 && (
                    <div>
                      <div className="title2">Work Experience</div>
                      {workExperiences.map((experience, index) => (
                        <div key={index} className="box">
                          <div className="year-company">
                            <div className="start">{experience.startDate}{" - "}{experience.endDate}</div>
                            <div><strong>{experience.companyName}</strong></div>
                          </div>
                          <div className="textDescription">
                            <div className="positionPosition">{experience.position}</div>
                            <div className="descriptionExperience">
                              {experience.jobDescription && <div><IoRemoveOutline />{experience.jobDescription}</div>}
                              {experience.additionalField1 && <div><IoRemoveOutline />{experience.additionalField1}</div>}
                              {experience.additionalField2 && <div><IoRemoveOutline />{experience.additionalField2}</div>}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="about">
                  {projects.length > 0 && (
                    <div>
                      <div className="title2">Projects</div>
                      {projects.map((project, index) => (
                        <div key={index} className="box">
                          <div className="year-company">
                            <div className="start">{project.completionYear}</div>
                            <div><strong>{project.techStack}</strong></div>
                          </div>
                          <div className="textDescription">
                            <div className="positionPosition">{project.projectName}</div>
                            <div className="descriptionExperience">
                              {project.projectDescription && <div><IoRemoveOutline />{project.projectDescription}</div>}
                              {project.additionalField1 && <div><IoRemoveOutline />{project.additionalField1}</div>}
                              {project.additionalField2 && <div><IoRemoveOutline />{project.additionalField2}</div>}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="about skillsAlign">
                  {professionalSkills.length > 0 && (
                    <div>
                      <div className="title2">Professional Skills</div>
                      {professionalSkills.map((professionalSkill, index) => (
                        <div key={index}>
                          <div className="box">
                            <div className="skillAlignValue">{professionalSkill.name}</div>
                            <div className="percentSkillAlign">
                              <div style={{width: `${professionalSkill.fluency * 10}%`,}}></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Template2;