import React, { useState } from "react";
import './LoginPopup.css';
import './style.css';
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { auth, db } from './firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInWithGoogle from './signInWithGoogle';

const AuthPopup = ({ onClose, onLoginSuccess }) => { // Receive onLoginSuccess prop
  const [isLogin, setIsLogin] = useState(true); // Toggle between login and sign-up
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [uname, setUname] = useState('');
  const navigate = useNavigate(); // Initialize navigate hook

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Logged in Successfully!!", {
        position: "top-center",
      });
      onClose();
      if (onLoginSuccess) {
        onLoginSuccess(); // Call onLoginSuccess callback
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          userName: uname,
        });
      }
      toast.success("Registered Successfully!!", {
        position: "top-center",
      });
      setEmail('');
      setPassword('');
      setUname('');
      onClose();
      if (onLoginSuccess) {
        onLoginSuccess(); // Call onLoginSuccess callback
      }
    } catch (err) {
      toast.error(err.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <div className="login-popup">
      <div className="login-popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        {isLogin ? (
          <form className="signup-form" onSubmit={handleLoginSubmit}>
            <h2>Login</h2>
            <label htmlFor="email">Email:
              <input type="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} id="email" autoComplete="email" />
            </label>
            <label htmlFor="password">Password:
              <input type="password" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" autoComplete="off" />
            </label>
            <button type="submit">Login</button>
            <p className="switch-auth">Don't have an account? <span onClick={() => setIsLogin(false)} className="sign-nav">Sign Up</span></p>
            <SignInWithGoogle onClose={onClose} navigate={navigate} />
          </form>
        ) : (
          <form className="signup-form" onSubmit={handleSignUpSubmit}>
            <h2>Sign Up</h2>
            <label htmlFor="first-name">User Name:
              <input type="text" value={uname} placeholder="Full Name" onChange={(e) => setUname(e.target.value)} id="first-name" required autoComplete="username" />
            </label>
            <label htmlFor="email">Email:
              <input type="email" value={email} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} id="email" required autoComplete="email" />
            </label>
            <label htmlFor="password">Password:
              <input type="password" value={password} placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} id="password" autoComplete="off" />
            </label>
            <button type="submit">Sign Up</button>
            <p className="switch-auth">Already have an account? <span onClick={() => setIsLogin(true)} className="sign-nav">Login</span></p>
            <SignInWithGoogle onClose={onClose} />
          </form>
        )}
      </div>
    </div>
  );
};

export default AuthPopup;
