import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
  apiKey: "AIzaSyCfb4fRp9oGyK37yYWJYz6f3oGeO1Ba24U",
  authDomain: "techealer-registration.firebaseapp.com",
  projectId: "techealer-registration",
  storageBucket: "techealer-registration.appspot.com",
  messagingSenderId: "302029543644",
  appId: "1:302029543644:web:d66e8b97df6ed699bc7c19"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const db = getFirestore(app);
export {auth};