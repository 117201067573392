import React, { useState } from "react";
import PersonalInfoForm from "../Components/PersonalInfoForm";
import WorkExperienceForm from "../Components/WorkExperienceForm";
import EducationForm from "../Components/EducationForm";
import ProjectForm from "../Components/ProjectForm";
import SkillsForm from "../Components/SkillsForm";
import CertificateForm from "../Components/CertificateForm";
import LanguageForm from "../Components/LanguageForm";
import InterestForm from "../Components/InterestForm";
import { FaPhone } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import "./Template1.css";

function Template1() {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [education, setEducation] = useState(null);
  const [projects, setProjects] = useState([]);
  const [skill, setSkills] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [language, setLanguage] = useState([]);
  const [interest, setInterest] = useState([]);
  const [showWorkExperienceForm, setShowWorkExperienceForm] = useState(false);
  const [showEducationForm, setShowEducationForm] = useState(false);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showSkillsForm, setShowSkillsForm] = useState(false);
  const [showCertificateForm, setShowCertificateForm] = useState(false);
  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [showInterestForm, setShowInterestForm] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);

  const handlePersonalInfoNext = (data) => {
    setPersonalInfo(data);
    setShowWorkExperienceForm(true);
  };

  const handleWorkExperienceNext = (data) => {
    setWorkExperiences(data);
    setShowWorkExperienceForm(false);
    setShowEducationForm(true);
  };

  const handleEducationNext = (data) => {
    setEducation(data);
    setShowEducationForm(false);
    setShowProjectForm(true);
  };

  const handleProjectNext = (data) => {
    setProjects(data);
    setShowProjectForm(false);
    setShowSkillsForm(true);
  };

  const handleSkillsNext = (data) => {
    setSkills(data);
    setShowSkillsForm(false);
    setShowCertificateForm(true);
  };

  const handleCertificateNext = (data) => {
    setCertificate(data);
    setShowCertificateForm(false);
    setShowLanguageForm(true);
  };

  const handleLanguageNext = (data) => {
    setLanguage(data);
    setShowLanguageForm(false);
    setShowInterestForm(true);
  };
  const handleInterestNext = (data) => {
    setInterest(data);
    setShowTemplate(true);
  };

  const handleWorkExperiencePrevious = () => {
    setShowWorkExperienceForm(false);
  };

  const handleEducationPrevious = () => {
    setShowWorkExperienceForm(true);
    setShowEducationForm(false);
  };

  const handleProjectPrevious = () => {
    setShowEducationForm(true);
    setShowProjectForm(false);
  };

  const handleSkillsPrevious = () => {
    setShowProjectForm(true);
    setShowSkillsForm(false);
  };

  const handleCertificatePrevious = () => {
    setShowSkillsForm(true);
    setShowCertificateForm(false);
  };

  const handleLanguagePrevious = () => {
    setShowCertificateForm(true);
    setShowLanguageForm(false);
  };

  const handleInterestPrevious = () => {
    setShowLanguageForm(true);
    setShowInterestForm(false);
  };

  const hideButtonAndPrint = () => {
    var button = document.getElementById("downloadButton");
    button.classList.add("hidden");
    window.print();
  }

  return (
    <div>
      {!showWorkExperienceForm && !showEducationForm && !showProjectForm && !showSkillsForm && !showCertificateForm && !showLanguageForm && !showInterestForm && !showTemplate && (
        <div>
          <PersonalInfoForm onNext={handlePersonalInfoNext} />
        </div>
      )}
      {showWorkExperienceForm && !showEducationForm && !showProjectForm && !showSkillsForm && !showCertificateForm && !showLanguageForm && !showInterestForm && !showTemplate && (
        <div>
          <WorkExperienceForm onNext={handleWorkExperienceNext} onPrevious={handleWorkExperiencePrevious} />
        </div>
      )}
      {showEducationForm && !showProjectForm && !showSkillsForm && !showCertificateForm && !showLanguageForm && !showInterestForm && !showTemplate && (
        <div>
          <EducationForm onNext={handleEducationNext} onPrevious={handleEducationPrevious} />
        </div>
      )}
      {showProjectForm && !showSkillsForm && !showCertificateForm && !showLanguageForm && !showInterestForm && !showTemplate && (
        <div>
          <ProjectForm onNext={handleProjectNext} onPrevious={handleProjectPrevious} />
        </div>
      )}
      {showSkillsForm && !showCertificateForm && !showLanguageForm && !showInterestForm && !showTemplate && (
        <div>
          <SkillsForm onNext={handleSkillsNext} onPrevious={handleSkillsPrevious} />
        </div>
      )}
      {showCertificateForm && !showLanguageForm && !showInterestForm && !showTemplate && (
        <div>
          <CertificateForm onNext={handleCertificateNext} onPrevious={handleCertificatePrevious} />
        </div>
      )}
      {showLanguageForm && !showInterestForm && !showTemplate && (
        <div>
          <LanguageForm onNext={handleLanguageNext} onPrevious={handleLanguagePrevious} />
        </div>
      )}
      {showInterestForm && !showTemplate && (
        <div>
          <InterestForm onNext={handleInterestNext} onPrevious={handleInterestPrevious} />
        </div>
      )}
      
      <div>
        {showTemplate && (
          <div className="html-container">
            <div>
              <button id="downloadButton" onClick={hideButtonAndPrint}>DOWNLOAD</button>
            </div>
            {personalInfo && (
              <div>
                <div className="profile">
                  <div className="name">{personalInfo.fullName}</div>
                  <div className="location">{personalInfo.title}{", "}{personalInfo.location}</div>
                  <div className="details">
                    {personalInfo.phone && <div><FaPhone size="14px" style={{ marginRight: "4px" }} className="res-icon" />{personalInfo.phone}{" "}</div>}
                    {personalInfo.email && <div><MdMail size="16px" style={{ marginRight: "4px" }}  className="res-icon" />{personalInfo.email}{" "}</div>}
                    {personalInfo.linkedIn && <div><FaLinkedin size="16px" style={{ marginRight: "4px" }}  className="res-icon" />{personalInfo.linkedIn}{" "}</div>}
                    {personalInfo.github && <div><FaGithub size="16px" style={{ marginRight: "4px" }} className="res-icon" />{personalInfo.github}</div>}
                  </div>
                </div>
                <div className="headingText">Summary</div>
                {personalInfo.description && <div className="description">{personalInfo.description}</div>}
              </div>
            )}

            {education.length>0 && (
              <div>
                <div className="headingText">Education</div>
                {education.map((education, index) => (
                  <div key={index}>
                    <div className="align1">
                      <div className="college">{education.collegeName}</div>
                      <div className="eduPeriod">{education.startDate}{"-"}{education.endDate}</div>
                    </div>
                    <div className="align1">
                      <div className="course">{education.courseName} {" in "}{education.stream}</div>
                      {education.cgpaPercent && <div className="cgpa">{education.cgpaPercent} {" CGPA"}</div>}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {workExperiences.length > 0 && (
              <div>
                <div className="headingText">Work Experience</div>
                {workExperiences.map((experience, index) => (
                  <div key={index}>
                    <div className="align1">
                      <div className="companyName">{experience.companyName}</div>
                      <div className="date">{experience.startDate}{" - "}{experience.endDate}</div>
                    </div>
                    <div className="align1">
                      <div className="positionCompany">{experience.position}</div>
                      {experience.location && <div className="jobLocation">{experience.location}</div>}
                    </div>
                    <ul className="descriptionJob">
                      {experience.jobDescription && <li>{experience.jobDescription}</li>}
                      {experience.additionalField1 && <li>{experience.additionalField1}</li>}
                      {experience.additionalField2 && <li>{experience.additionalField2}</li>}
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {projects.length > 0 && (
              <div>
                <div className="headingText">Projects</div>
                {projects.map((project, index) => (
                  <div key={index}>
                    <div className="align1">
                      <div className="projectName">{project.projectName}</div>
                      <div className="projectYear">{project.completionYear}</div>
                    </div>
                    <div className="align1">
                      <div className="techStack">{project.techStack}</div>
                      {project.githubLink && <div className="projectLink">{project.githubLink}</div>}
                    </div>
                    <ul className="projectDescription">
                      {project.projectDescription && <li>{project.projectDescription}</li>}
                      {project.additionalField1 && <li>{project.additionalField1}</li>}
                      {project.additionalField2 && <li>{project.additionalField2}</li>}
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {skill.length > 0 && (
              <div>
                <div className="headingText">Skills</div>
                {skill.map((skill, index) => (
                  <div key={index}>
                    <ul className="skills">
                      <li className="skillValue"><strong>{skill.name}{": "}</strong>{skill.value}</li>
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {certificate.length > 0 && (
              <div>
                <div className="headingText">Certificate</div>
                {certificate.map((certificate, index) => (
                  <div key={index}>
                    <ul className="certificateColumn">
                      <li><span className="certificateName">{certificate.name}</span>{" - "} {certificate.description}</li>
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {language.length > 0 && (
              <div>
                <div className="headingText">Language</div>
                <ul className="language">
                {language.map((language, index) => (
                  <div key={index}>
                      <li className="languageName">{language.name}</li>
                  </div>
                ))}
                </ul>
              </div>
            )}
            
            {interest.length > 0 && (
              <div>
                <div className="headingText">Interest</div>
                <ul className="interest">
                {interest.map((interest, index) => (
                  <div key={index}>
                    <li className="interestName">{interest.name}</li>
                  </div>
                ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Template1;