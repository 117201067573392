import React from "react";
import { useNavigate } from 'react-router';
import "./Development.css";

const Development = () => {
    const history = useNavigate();
  const navigateToContactComponent = () => {
    history('/contact');
  };
    const steps = [
        { count: 1, title: 'Understanding Your Needs', content: 'We delve into your goals, audience, and desired features to customize the development process to meet your specific requirements effectively.' },
        { count: 2, title: 'Crafting Your Design', content: 'Our design team brings your vision to life, creating intuitive and visually appealing interfaces that engage your audience.' },
        { count: 3, title: 'Development Phase', content: 'Our developers utilize the latest technologies and best practices to build scalable and robust solutions tailored to your business needs.' },
        { count: 4, title: 'Testing & Launch', content: 'We rigorously test your application to ensure it meets quality standards and provide support during the launch phase for a seamless deployment.' }
    ];
    return (
        <div>
            <h2 className="development-line1">From Concept to Launch: Our Website Development Process</h2>
            <h3 className="development-line2">At Techealer, we guide you through a smooth and efficient website development journey. Our process ensures that your website not only meets but exceeds your expectations. Here's how we do it:</h3>
            <div className="circle-container">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className="circle-item">
                            <div className="circle">{step.count}</div>
                            <div className="circle-title">{step.title}</div>
                            <div className="circle-content">{step.content}</div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="development-button" onClick={navigateToContactComponent} style={{cursor:"pointer"}}>Let's Talk</div>
        </div>
    )
}

export default Development;