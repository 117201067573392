import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Home.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ZigZag from './Asset1/ZigZagImg.webp';
import rocket from './Asset1/rocketImage.webp';
import patent from './Asset1/Patent.webp';
import appDesign from './Asset1/appDesign.webp';
import webDesign from './Asset1/webDesign.webp';
import Person1 from './Asset1/person1.webp';
import Person2 from './Asset1/person2.webp';
import Person3 from './Asset1/person3.webp';
import Person4 from './Asset1/person4.webp';
import ResumeVideo from './Asset1/ResumeVideo.mp4';

const Home = () => {
  useEffect(() => {
    document.title = "Techealer Infotech: Empowering Innovation";
  }, []);

  useEffect(() => {
    AOS.init();
  }, [])

  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);

  return (
    <main className='home-body-container'>
      <section className='row-wrap wrap-row'>
        <div className='col6' data-aos="fade-in" data-aos-easing="linear">
          <header className='mainHeading1'><h1>Conquering the Tech World with Top-notch Expertise</h1></header>
          <div className='row-wrap wrap-tool'>
            <div className='patent service-button' onClick={() => navigateTo('/patent')}>Patents</div>
            <div className='ai service-button' onClick={() => navigateTo('/aiTools')}>Free AI Tools</div>
            <div className='brand service-button' onClick={() => navigateTo('/brand')}>Branding</div>
            <div className='website service-button' onClick={() => navigateTo('/website')}>Website</div>
            <div className='finance service-button' onClick={() => navigateTo('/finance')}>Financial</div>
            <div className='intern service-button' onClick={() => navigateTo('/internship')}>Internship</div>
          </div>
        </div>
        <div className='col4'>
          <div className='animate-image' data-aos="fade-in">
            <LazyLoadImage src={rocket} alt='rocketImage' />
          </div>  
        </div>
      </section>
      <section className='mainHeading2'>
        <h2>What We Do</h2>
      </section>
      <section className='whatDo' data-aos="fade-in">
        <div className='Do1'>
            <div className='whatImg1' data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500"><LazyLoadImage src={patent} alt='patentImage' title='Patent Services' onClick={() => navigateTo('/patent')} style={{cursor:"pointer" }} /></div>
            <div className='textPart' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000"><div className='whatDoHeading'>Patent Services</div>
            <div className='whatDoDescription'>
              We offer end-to-end patent services, from analysis and filing to portfolio management and addressing challenges. Trust us for precise drafting, navigating complexities, and providing insightful reports.
            </div></div>
        </div>
        <div className='Do2'>
            <div className='whatImg2' data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500"><LazyLoadImage src={webDesign} alt='arImage' title='Website Development' onClick={() => navigateTo('/website')} style={{cursor:"pointer" }} /></div>
            <div className='textPart' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000"><div className='whatDoHeading'>Website Development</div>
            <div className='whatDoDescription'>Techealer Infotech crafts captivating websites and executes data-driven marketing strategies to boost your online presence and drive measurable growth. Your digital success story starts with us.</div></div>
        </div>
        <div className='Do3'>
            <div className='whatImg3' data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500"><LazyLoadImage src={appDesign} alt='arImage' title='Brand Management' onClick={() => navigateTo('/brand')} style={{cursor:"pointer" }} /></div>
            <div className='textPart' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1000"><div className='whatDoHeading'>Brand Management</div>
            <div className='whatDoDescription'>Transform your vision into an iconic brand with us! Unique identities, memorable experiences, and innovative design.Stand out, inspire, and engage your audience. Let's make your brand unforgettable!</div></div>
        </div>
      </section>
      <section className='mainHeading3'>
        <div>
            <div className='techBrand' data-aos="fade-in">We Protect Your Tech And Brand</div>
            <div style={{width:"200px", margin:"20px auto"}}><LazyLoadImage src={ZigZag} alt='zig-zag' style={{transform:"rotate(3deg)"}} title='Techealer Protection' /></div>
            <div className='protection' data-aos="fade-in">Techealer Protection</div>
        </div>
        <div>
            <div className='techProtection'>
                <div className='tech1'>Trademark registration</div>
                <div className='tech2'>Patent Search</div>
                <div className='tech3'>Infringement Search</div>
                <div className='tech4'>Patent Filling</div>
                <div className='tech5'>Draft Creation</div>
                <div className='tech6'>Invalidation</div>     
            </div>
        </div>
      </section>
      <section className='resumeHeading' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">
        <div className='resumeContent'>
            <div className='resumeHeadingText'>USE OUR AI RESUME BUILDER</div>
            <div className='resumeHeadingPara'>Now create your resume with different templates and download it for free, you don't need any graphic or tools skills anymore our AI tool will do everything for you</div>
            <button className='buttonResume' onClick={() => navigateTo('/resume')}>Build Resume</button>
        </div>
        <div>
          <video autoPlay muted loop src={ResumeVideo} type="video/mp4" width="100%" height="550px" style={{objectFit:"cover"}} className="reliable-video-style" />
        </div>
      </section>
      <section className='marketingService'>
        <div className='influencerSide1'>
            <div className='influencerHeading' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">Our Influence Marketing Services</div>
            <div className='influencerSide2'>
              <div>
                <div className='inflImg' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="2000"><LazyLoadImage src={Person3} alt='person3' title='Helene Pacquet, Entrepreneur' /></div>
                <div className='inflDescription1'>
                  <div className='inflName'>Helene Pacquet</div>
                  <div className='inflTitle'>Entrepreneur</div>
                </div>
              </div>
              <div>
                <div className='inflImg' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="2000"><LazyLoadImage src={Person4} alt='person4' title='Matt Zhangyee, Marketing Manager' /></div>
                  <div className='inflDescription2'>
                    <div className='inflName'>Matt Zhangyee</div>
                    <div className='inflTitle'>Marketing Manager</div>
                  </div>
                </div>
              </div>
            </div>
        <div className='influencerSide2'>
          <div>
            <div className='inflImg' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="2000"><LazyLoadImage src={Person1} alt='person1' title='Viggo Magnusson, Social Media Specialist' /></div>
            <div className='inflDescription3'>
              <div className='inflName'>Viggo Magnusson</div>
              <div className='inflTitle'>Social Media Specialist</div>
            </div>
          </div>
          <div>
            <div className='inflImg' data-aos="fade-in" data-aos-easing="linear" data-aos-duration="2000"><LazyLoadImage src={Person2} alt='person2' title='Jiara Martins, Restaurant Owner' /></div>
            <div className='inflDescription4'>
              <div className='inflName'>Jiara Martins</div>
              <div className='inflTitle'>Restaurant Owner</div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Home