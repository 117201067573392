import React, { useState, useEffect } from 'react';
import './Resume.css';
import Template1 from '../../Templates/Template1';
import Template2 from '../../Templates/Template2';
import Template3 from '../../Templates/Template3';
import Template4 from '../../Templates/Template4';
import temp1 from '../../Assets/Template1.webp';
import temp2 from '../../Assets/Template2.webp';
import temp3 from '../../Assets/Template3.webp';
import temp4 from '../../Assets/Template4.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../Registration/AuthContext';
import AuthPopup from '../../Registration/AuthPopup'; // Import the AuthPopup component
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const Resume = () => {
  const { user } = useAuth(); // Get the login state from the context
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  useEffect(() => {
    document.title = "Craft a Resume that Gets Noticed";
  }, []);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showAuthPopup, setShowAuthPopup] = useState(false); // State for showing auth pop-up

  const templates = [
    { name: 'Template1', imgSrc: temp1 },
    { name: 'Template2', imgSrc: temp2 },
    { name: 'Template3', imgSrc: temp3 },
    { name: 'Template4', imgSrc: temp4 }
  ];

  const links = [
    'https://drive.google.com/file/d/1nl4uJQp1_Ap8aDW0pGcyqPxTwu6NEmcW/view?usp=sharing',
    'https://drive.google.com/file/d/1vVhgx7E3dAnHyGwmY9PIgOipF99EiPzX/view?usp=sharing',
    'https://drive.google.com/file/d/122rdrelmQhmSI2H68BpBvGuN9yttJ6eL/view?usp=sharing',
    'https://drive.google.com/file/d/15jz0A4lY446gJd3UxHTefNtM0wJeeGIN/view?usp=sharing'
  ];

  const handleTemplateChange = (template) => {
    setSelectedTemplate(template);
    window.scrollTo(0, 0);
  };

  const openPdfInNewTab = (index) => {
    window.open(links[index], '_blank');
  };

  const handleAuthClick = () => {
    setShowAuthPopup(true); // Show auth pop-up
  };

  const onLoginSuccess = () => {
    setShowAuthPopup(false); // Close auth pop-up
    navigate('/resume'); // Redirect to /resume page using navigate function
  };

  return (
    <div className='resume'>
      {!selectedTemplate && (
        <nav>
          <div className="navigation">
            {templates.map((template, index) => (
              <div key={index} className={`temp${index + 1}`}>
                <div className='tempImg1'>
                  <LazyLoadImage
                    src={template.imgSrc}
                    alt={`template${index + 1}`}
                    onClick={() => openPdfInNewTab(index)}
                    className={index > 1 && !user ? 'blur' : ''}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className="buttonAlign">
                  {index > 1 && !user ? (
                    <button onClick={handleAuthClick} className="buttonStyling">
                      Login
                    </button>
                  ) : (
                    <button
                      onClick={() => handleTemplateChange(template.name)}
                      className="buttonStyling"
                      disabled={index > 1 && !user}
                    >
                      Edit Template
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </nav>
      )}
      {selectedTemplate === 'Template1' && <Template1 />}
      {selectedTemplate === 'Template2' && <Template2 />}
      {selectedTemplate === 'Template3' && <Template3 />}
      {selectedTemplate === 'Template4' && <Template4 />}
      {showAuthPopup && <AuthPopup onClose={() => setShowAuthPopup(false)} onLoginSuccess={onLoginSuccess} />} {/* Display Auth pop-up */}
    </div>
  );
};

export default Resume;
