import React, {useEffect} from 'react';

const Contact = () => {
  useEffect(() => {
    document.title = "Contact Techealer Today!";
  }, []);
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"50px"}}>
      <iframe title="contact-form" aria-label='Contact Us' frameBorder="0" style={{height:"600px", width:"70%", border:"none"}} src='https://forms.zohopublic.in/techealerinfotech/form/ContactUs/formperma/4LDK_M-AV_sHwbM2b3Qp0V2a7e7OKIMjf7Mhor06aXI'></iframe>
    </div>
  );
}

export default Contact;
