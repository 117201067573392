import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './SocialAi.css';
import OccassionalImage from "./SocialAiAsset/OccassionalImage.webp";
import BuisnessImage from "./SocialAiAsset/BuisnessImage.webp";
import OtherImage from "./SocialAiAsset/OtherImage.webp";

const Card = ({ link, imgSrc, title, description, date, tags }) => {
  return (
    <div className="social-grid-space">
      <Link className="social-card" to={link} style={{ '--bg-img': `url(${imgSrc})` }}>
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="date">{date}</div>
          <div className="tags">
            {tags.map(tag => (
              <div className="tag" key={tag}>{tag}</div>
            ))}
          </div>
        </div>
      </Link>
    </div>
  );
};

const CardsWrapper = () => {
  const cardsData = [
    {
      number: 1,
      link: '/occasional',
      imgSrc: OccassionalImage,
      title: 'Ocassion',
      description: 'Join us in celebrating the joy of the season with festive cheer and delightful moments!',
      tags: ['Next']
    },
    {
      number: 2,
      link: '/buisness',
      imgSrc: BuisnessImage,
      title: 'Business',
      description: 'Leading the way in innovation and excellence, committed to your business success and growth.',
      tags: ['Next']
    },
    {
      number: 3,
      link: '/contact',
      imgSrc: OtherImage,
      title: 'Other',
      description: 'The syntax of a language is how it works. How to actually write it.',
      tags: ['Next']
    }
  ];

  return (
    <section className="social-cards-wrapper">
      {cardsData.map(card => (
        <Card key={card.number} {...card} />
      ))}
    </section>
  );
};

const SocialAi = () => {
  useEffect(() => {
    document.title = "Effortless Social Media Content Creation";
  }, []);
  return (
    <div>
      <CardsWrapper />
    </div>
  )
}

export default SocialAi;