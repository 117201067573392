import React, {useEffect} from "react";
import { useNavigate } from 'react-router';
import "./Finance.css";
import FinanceImgPhone from "./Finance-Asset/fin-img1.webp";
import FinanceImgLaptop from "./Finance-Asset/fin-img2.webp";
import PersonBlock from "./Finance-Asset/PersonBlock.webp";
import ReliableSupport from "./ReliableSupport";
import { LazyLoadImage } from "react-lazy-load-image-component";


const Finance = () => {
    useEffect(() => {
        document.title = "Funding Your Invention: Resources and Strategies from Techealer";
      }, []);

    const history = useNavigate();
    const navigateToCalculatorComponent = () => {
         history('/calculator');
    };

    return (
        <div>
            <div className="finance-div-1">
                <div className="fin-div-1-sp">Financial + Solutions</div>
                <div className="finance-div-1-parts">
                    <div className="fin-div-1-left">
                        <h1 className="fin-left1-heading">It's our mission to make people's lives easier.</h1>
                        <div className="fin-left1-desc">Let's get started with yours.</div>
                    </div>
                    <div className="fin-div-1-right"></div>
                </div>
            </div>
            <div className="finance-div2">
                <div className="fin-left">
                    <h2 className="promise-heading">Our Promise</h2>
                    <div className="promise-description">Your success is our success. We are dedicated to building long-term relationships with our clients, grounded in trust, transparency, and mutual respect. Our promise is to always act in your best interest, providing you with clear, honest, and actionable advice that empowers you to achieve your financial goals.</div>
                </div>
                <div className="fin-right">
                    <div className="fin-right-subPart">
                        <div className="sub-1">WEALTH CREATION</div>
                        <div className="sub-2"><div className="fin-right-learn" onClick={navigateToCalculatorComponent}>Learn More</div></div>
                    </div>
                    <div className="fin-right-subPart">
                        <div className="sub-1">CHILD FUTURE</div>
                        <div className="sub-2"><div className="fin-right-learn" onClick={navigateToCalculatorComponent}>Learn More</div></div>
                    </div>
                    <div className="fin-right-subPart">
                        <div className="sub-1">RETIREMENT PLAN</div>
                        <div className="sub-2"><div className="fin-right-learn" onClick={navigateToCalculatorComponent}>Learn More</div></div>
                    </div>
                </div>
            </div>
            <div className="fin-div-3">
                <h2 className="fin-div-heading">Our Track Record Speaks for Itself</h2>
                <div className="fin-div-3-parts">
                    <div className="fin-div-3-image1 fin-div-3-lR">
                        <div><LazyLoadImage src={FinanceImgPhone} alt="finance" className="fin-image-phone" /></div>
                        <div>Need quick returns for immediate goals? Short-term investments offer flexibility and fast gains but come with higher volatility. Balance with long-term options for a solid financial strategy! Ready to diversify?</div>
                    </div>
                    <div className="fin-div-3-image2 fin-div-3-lR">
                        <div><LazyLoadImage src={FinanceImgLaptop} alt="finance-laptop" className="fin-image-phone" /></div>
                        <div>Thinking about your future? Long-term investments grow wealth by harnessing compound interest and market gains. Patience + consistency = financial security. Ready to plant your money seeds today?"</div>
                    </div>
                </div>
            </div>
            <div><ReliableSupport /></div>
            <div className="fin-div-4">
                <h2 className="fin-div-4-heading">We Provide</h2>
                <div className="fin-div-4-parts">
                    <div className="fin-div-4-left">
                        <div className="fin-div-4-block">
                            <div className="block-4-heading">Mutual Funds</div>
                            <div className="block-4-description">Pool your money, diversify your risk, and let experts grow your investment</div>
                        </div>
                        <div className="fin-div-4-block">
                            <div className="block-4-heading">Term Insurance</div>
                            <div className="block-4-description">Providing essential financial protection for your loved ones, one term at a time</div>
                        </div>
                        <div className="fin-div-4-block">
                            <div className="block-4-heading">Health Insurance</div>
                            <div className="block-4-description">Protecting your health and finances, providing peace of mind when it matters most</div>
                        </div>
                        <div className="fin-div-4-block">
                            <div className="block-4-heading">Educational Plans</div>
                            <div className="block-4-description">Building a brighter future for your child's education, one investment at a time</div>
                        </div>
                    </div>
                    <div className="fin-div-4-right"><div className="fin-div-4-right-image"><LazyLoadImage src={PersonBlock} alt="person-block" /></div></div>
                </div>
            </div>
        </div>
    )
}

export default Finance