import React from 'react';
import { useNavigate } from 'react-router';
import "../../SocialComponent/Occasional/Occasional.css";
import GymPost from "../BuisnessAsset/GymPost.jpg";
import EducationalBanner from "../BuisnessAsset/EducationalBanner.png";
import OtherBanner from "../BuisnessAsset/OtherBanner.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BusinessPage = () => {
  const history = useNavigate();
  const navigateToGymComponent = () => {
    history('/gym');
  };
  const navigateToEducationalComponent = () => {
    history('/education');
  };
  const navigateToOtherComponent = () => {
    history('/other');
  };
  return (
    <ul className="occasional-cards">
      <li>
        <div onClick={navigateToGymComponent} className="occasional-card">
          <LazyLoadImage src={GymPost} className="occasional-card__image" alt=""/>
          <div className="occasional-card__overlay">
            <div className="occasional-card__header">
              <svg className="occasional-card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              <div className="occasional-card__header-text">
                <h3 className="occasional-card__title">Gym</h3>
              </div>
            </div>
            <p className="occasional-card__description">Unleash your strength with cutting-edge equipment and tailored workouts. Your transformation starts here, where every rep counts.</p>
          </div>
        </div>
      </li>
      <li>
        <div onClick={navigateToEducationalComponent} className="occasional-card">
          <LazyLoadImage src={EducationalBanner} className="occasional-card__image" alt=""/>
          <div className="occasional-card__overlay">
            <div className="occasional-card__header">
              <svg className="occasional-card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              <div className="occasional-card__header-text">
                <h3 className="occasional-card__title">Educational Banner</h3>
              </div>
            </div>
            <p className="occasional-card__description">Inspire growth and ignite curiosity with innovative learning. At our institution, we transform potential into success through education, equipping you with the tools to thrive in an ever-changing world.</p>
          </div>
        </div>
      </li>
      <li>
        <div onClick={navigateToOtherComponent} className="occasional-card">
          <LazyLoadImage src={OtherBanner} className="occasional-card__image" alt=""/>
          <div className="occasional-card__overlay">
            <div className="occasional-card__header">
              <svg className="occasional-card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              <div className="occasional-card__header-text">
                <h3 className="occasional-card__title">Others</h3>
              </div>
            </div>
            <p className="occasional-card__description">Discover new possibilities with us. Explore innovative solutions and elevate your experience with cutting-edge services and expertise.</p>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default BusinessPage;