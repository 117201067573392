import React, {useEffect} from 'react';

const Internship = () => {
  useEffect(() => {
    document.title = "Techealer Internships: Gain Valuable Experience in Patent & Innovation";
  }, []);
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"50px"}}>
      <iframe title="intern-form" aria-label='Internship' frameBorder="0" style={{height:"600px", width:"70%", border:"none"}} src='https://forms.zohopublic.in/techealerinfotech/form/Internship/formperma/n-y-oPW-2yG0lp1BDDmBVqWdJJEFIVilzt5XqcmRrP4'></iframe>
    </div>
  );
}

export default Internship;