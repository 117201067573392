import React, {useEffect} from 'react';
import { useNavigate } from 'react-router';
import "./Website.css";
import MainWebsitePhoto from "./WebsiteAsset/mainWebsitePhoto.webp";
import Stunning from "./WebsiteAsset/Stunning.webp";
import UniqueBranding from "./WebsiteAsset/UniqueBranding.webp";
import WebsiteDevelopment from "./WebsiteAsset/WebsiteDevelopment.webp";
import TrustedCompany from './TrustedCompany';
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import StunningWebsite from './StunningWebsite';
import Development from './Development';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Website = () => {
  useEffect(() => {
    document.title = "Techealer Design: Design Your Dream Website";
  }, []);

  const history = useNavigate();
  const navigateToContactComponent = () => {
    history('/contact');
  };
  return (
    <div>
      <div className='website-div-1'>
        <div className='wDiv1-left'>
            <div className='wLeft-line1'>We're Techealer</div>
            <h1 className='wLeft-line2'>Trusted Website Designing Company You Can Rely On!</h1>
            <div className='wLeft-Line3'>We craft high-conversion websites that effectively turn your visitors into loyal customers.</div>
            <div className='wLeft-Line4' onClick={navigateToContactComponent} style={{cursor:"pointer"}}>Get Started</div>
        </div>
        <div className='wDiv1-right'>
            <div className='wDiv1-image'>
                <LazyLoadImage src={MainWebsitePhoto} alt='website-main' className='main-img-website' />
            </div>
        </div>
      </div>
      <div><StunningWebsite /></div>
      <h2 className='website-benefit4'>Benefits of choosing Techealer as your Website Designers</h2>
      <div className='website-div-4'>
        <div className='wDiv4-left'>
          <div className='wLeft4-Line1'>Stunning Website Designs</div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>We success is our dedication to delivering visually stunning websites through collaboration with our expert web designers.</div></div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Offering tailor-made designs that translate clients' visions into reality.</div></div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Ensuring a seamless user experience with responsive, mobile-friendly, and user-friendly interfaces.</div></div>
        </div>
        <div className='wDiv4-right'>
          <img src={Stunning} alt='stunning' className='wDiv4-img' />
        </div>
      </div>
      <div className='website-div-4-second'>
        <div className='wDiv4-right'>
          <LazyLoadImage src={UniqueBranding} alt='stunning' className='wDiv4-img' />
        </div>
        <div className='wDiv4-left'>
        <div className='wLeft4-Line1'>Unique Branding</div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Designing unique websites that reflect your business's individuality through close collaboration with our designers.</div></div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Creating memorable first impressions and establishing trust with a strong visual language.</div></div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Tailoring solutions to align perfectly with your brand identity for credibility.</div></div>
        </div>
      </div>
      <div className='website-div-4'>
        <div className='wDiv4-left'>
          <div className='wLeft4-Line1'>Website Development Excellence</div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Crafting visually stunning websites with intelligent functionality for an effective online presence.</div></div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Prioritizing optimized performance with faster loading speeds for user satisfaction.</div></div>
          <div className='wLeft4-Line2'><div><TiTick size={"30px"} style={{color:"#ff40bd"}} /></div><div>Ensuring security through robust measures to safeguard user data.</div></div>
        </div>
        <div className='wDiv4-right'>
          <LazyLoadImage src={WebsiteDevelopment} alt='stunning' className='wDiv4-img' />
        </div>
      </div>
      <div className='website-div-5'>
        <h2 className='wDiv5-Line1'>Why Choose Us As Your Website Designer</h2>
        <h3 className='wDiv5-Line2'>We're not just a website designer, we're your dedicated partner in achieving digital success. What sets us apart is our commitment to delivering visually stunning websites with intelligent design, tailoring solutions to fit your unique business needs.</h3>
      </div>
      <div className='website-table'><table style={{ width: "100%" }}>
              <thead>
                <tr className="wCol-width-height">
                    <th className="wCol-width-height w-thread head1">Benefits and Features</th>
                    <th className="wCol-width-height w-thread head2">That random company you saw in the ad behind an auto-rickshaw</th>
                    <th className="wCol-width-height w-thread head3">Techealer a Website Designing Company in World</th>
                </tr>
              </thead>
              <tbody>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">100% custom, handmade, and bespoke design tailored to your brand.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">Clear and transparent process; we guide you every step of the way.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">We empower you with design expertise to understand and control your tech.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">Dynamic, manageable website design that evolves with your business needs.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">Proactive client service; we keep you informed and engaged throughout.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">Premium pricing with clear and comprehensive service offerings.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">Ensured faster loading speed for an optimal user experience.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
                <tr className="wCol-width-height t-align-w">
                    <td className="wCol-width-height td1">Ensured faster loading speed for an optimal user experience.</td>
                    <td className="wCol-width-height td2" style={{color:"red"}}><ImCross size={"30px"} /></td>
                    <td className="wCol-width-height td1" style={{color:"#b4e8ba"}}><TiTick size={"42px"} /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 className='wDiv6-line1'>Trusted Website Designing Company in World!</h2>
          <h3 className='wDiv6-line2'>Limited Offer: Save big on your website packages with our cost-efficient prices. Hurry! - grab this fantastic deal before it disappears!</h3>
          <TrustedCompany />
          <Development />
    </div>
  )
}

export default Website