import React, { useState } from "react";
import './style.css';
import { Link, useNavigate } from "react-router-dom";
import { auth } from './firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInWithGoogle from './signInWithGoogle';

const Login = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login Successfully");
      toast.success("Logged in Successfully!!", {
        position: "top-center",
      });
      onClose(); // Close the pop-up
      navigate('/resume'); // Redirect to resume page
    } catch (err) {
      console.log(err.message);
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        <label htmlFor="email">Email:
          <input type="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} id="email" autoComplete="email" />
        </label>
        <label htmlFor="password">Password:
          <input type="password" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" autoComplete="off" />
        </label>
        <button type="submit">Login</button>
        <p className="already">Don't Have Account? <Link to="/register" className="sign-nav" onClick={onClose}>Register</Link></p>
        <SignInWithGoogle />
      </form>
    </div>
  );
};

export default Login;
