import React, {useEffect} from 'react';
import './Brand.css';

const Brand = () => {
  useEffect(() => {
    document.title = "Techealer Branding Solutions";
  }, []);
  return (
    <div>
      <div className='brand-first-container'>
        <h1 className='brand-line1'>Benefit from our tried and tested solutions.</h1>
        <div><div className='brand-line2'>GROW YOUR <div>COMPANY</div></div><div className='brand-market'>with marketing strategies that work</div></div>
        <div className='brand-line3'>Our Branding Solutions</div>
      </div>
      <div className='brand-second-container'>
        <div className='brand-leftSide'>
          <div className='enterprenur-image'>
            <div className='enterprenur-name'>Riddhi Poddar</div>
            <div className='enterprenur-title'>Branding Expertise</div>
          </div>
        </div>
        <div className='brand-rightSide'>
          <div className='brand-right-content'>
            <div className='brand-detail1' style={{backgroundColor:'#00c4cc'}}><div className='brand-detail'>Influence Marketing</div></div>
            <div className='brand-detail2' style={{backgroundColor:'#ff738e'}}><div className='brand-detail'>Tele Calling Marketing</div></div>
            <div className='brand-detail1' style={{backgroundColor:'#a548ff'}}><div className='brand-detail'>Social Media Marketing</div></div>
            <div className='brand-detail2' style={{backgroundColor:'#ffa53b'}}><div className='brand-detail'>Reputation Management</div></div>
            <div className='brand-detail1' style={{backgroundColor:'#6422b8'}}><div className='brand-detail'>Email Marketing</div></div>
            <div className='brand-detail2' style={{backgroundColor:'#ffd93b'}}><div className='brand-detail'>Event Marketing</div></div>
          </div>
        </div>
      </div>
      <div className="brandFeatures">
        <div>
          <h2 className="brand-feature-heading">Our Package</h2>
        </div>
        <div>
          <div className="brand-feature-table">
            <table style={{ width: "100%" }}>
              <thead>
                <tr className='brand-col-size'>
                    <th style={{borderLeftColor:'white', borderTopColor:'white'}} className='brand-col-size'></th>
                    <th className='brand-col-size basic-width th-size'>Basic</th>
                    <th className='brand-col-size th-size'>Advance</th>
                    <th className='brand-col-size th-size'>Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className='brand-col-size'>
                    <td style={{backgroundColor:'#d9f7e4'}} className="brand-col-size table-cl">Starter Package</td>
                    <td className='brand-col-size'>Consultant, Social Media(Facebook,Instagram), Digital Marketing</td>
                    <td className='brand-col-size'>Basic + Reputation Management, Email Marketing</td>
                    <td className='brand-col-size'>Basic + Advance + Telecalling</td>
                </tr>
                <tr className='brand-col-size'>
                    <td style={{backgroundColor:'#d9f7e4'}} className="brand-col-size table-cl">Speedup Package</td>
                    <td className='brand-col-size'>Consultant, Social Media(Facebook,Instagram,Twitter,Youtube), Digital Marketing</td>
                    <td className='brand-col-size'>Basic + Reputation Management, Email Marketing, Telecalling</td>
                    <td className='brand-col-size'>Basic + Advance + Influence Marketing</td>
                </tr>
                <tr className='brand-col-size'>
                    <td style={{backgroundColor:'#d9f7e4'}} className="brand-col-size table-cl">Expand Package</td>
                    <td className='brand-col-size'>Consultant, Social Media(Facebook,Instagram,Twitter,Youtube + 10), Digital Marketing</td>
                    <td className='brand-col-size'>Basic + Reputation Management, Email Marketing, Telecalling, Influence Marketing</td>
                    <td className='brand-col-size'>Basic + Advance + SEO</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Brand
