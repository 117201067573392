import React, { useState, useEffect } from 'react';

function LanguageForm({ onNext, onPrevious, language }) {
  // Retrieve data from session storage or initialize with default data
  const initialLanguages = JSON.parse(sessionStorage.getItem('languages')) || [{ name: '', fluency: '' }];

  const [languages, setLanguages] = useState(language || initialLanguages);

  useEffect(() => {
    sessionStorage.setItem('languages', JSON.stringify(languages)); // Store data in session storage
  }, [languages]);

  const handleAddLanguages = () => {
    setLanguages([...languages, { name: '', fluency: '' }]);
  };

  const handleRemoveLanguages = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedLanguages = [...languages];
    updatedLanguages[index][name] = value;
    setLanguages(updatedLanguages);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(languages);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Language Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {languages.map((language, index) => (
          <div key={index}>
            <div className="input-group">
              <label htmlFor={`languageName${index}`}>Language:</label>
              <input
                type="text"
                id={`languageName${index}`}
                name="name"
                value={language.name}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter Language Name [Ex:- English/Spanish]"
                autoComplete='language'
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`languageFluency${index}`}>Fluency:</label>
              <input
                type="number"
                id={`languageFluency${index}`}
                name="fluency"
                value={language.fluency}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter Fluency Level (1-10)"
                autoComplete='languageFluency'
                required
              />
            </div>
              <button type="button" onClick={() => handleRemoveLanguages(index)} className='removeButton'>Remove</button>
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddLanguages}>Add Language</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default LanguageForm;
