import React, { useState, useEffect } from 'react';
import SocialForm from "../../SocialForm/SocialForm";
import DiwaliTemplate1 from '../../SocialTemplates/Diwali/DiwaliTemplate1';
import DiwaliTemplate2 from '../../SocialTemplates/Diwali/DiwaliTemplate2';
import DiwaliTemplate3 from '../../SocialTemplates/Diwali/DiwaliTemplate3';
import DiwaliTemplate4 from '../../SocialTemplates/Diwali/DiwaliTemplate4';
import AuthPopup from '../../../Registration/AuthPopup'; // Import AuthPopup
import { useAuth } from '../../../Registration/AuthContext';
import './Diwali.css';

const Diwali = () => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false); // State to manage login popup
  const { user } = useAuth(); // Get the login state from the context

  const handleNext = () => {
    setIsFormVisible(false);
  };

  useEffect(() => {
    if (!isFormVisible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  }, [isFormVisible]);

  // Function to close the login popup
  const closeLoginPopup = () => {
    setIsLoginPopupOpen(false);
  };

  // Function to open the login popup
  const openLoginPopup = () => {
    setIsLoginPopupOpen(true);
  };

  const templates = [
    { id: 1, component: <DiwaliTemplate1 /> },
    { id: 2, component: <DiwaliTemplate2 /> },
    { id: 3, component: <DiwaliTemplate3 />, requiresLogin: true },
    { id: 4, component: <DiwaliTemplate4 />, requiresLogin: true },
  ];

  return (
    <div>
      {isFormVisible ? (
        <SocialForm onNext={handleNext} />
      ) : (
        <div className='templates-container'>
          <h2 className='template-header'>Select a Template</h2>
          <div className='templates-alignment'>
            {templates.map((template) => (
              <div key={template.id} className='template-wrapper'>
                <div className={`template ${!user && template.requiresLogin ? 'blurFeature' : ''}`}>
                  {template.component}
                </div>
                {!user && template.requiresLogin && (
                  <button className="login-button" onClick={openLoginPopup}>
                    Login
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {isLoginPopupOpen && <AuthPopup onClose={closeLoginPopup} />}
    </div>
  );
};

export default Diwali;
