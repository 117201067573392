import React from 'react';
import { useNavigate } from 'react-router';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import RubyCompany from "./WebsiteAsset/RubyCompany.webp";
import OvenCompany from "./WebsiteAsset/OvenCompany.webp";
import OrganicCompany from "./WebsiteAsset/OrganicCompany.webp";
import JivaCompany from "./WebsiteAsset/JivaCompany.webp";
import BibaCompany from "./WebsiteAsset/BibaCompany.webp";
import BehrouzCompany from "./WebsiteAsset/BehrouzCompany.webp";
import "./TrustedCompany.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TrustedCompany = () => {
  const history = useNavigate();
  const navigateToContactComponent = () => {
    history('/contact');
  };
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            }
          }
        ]
      };
  return (
    <div className='mainContainer'>
      <Slider {...settings}>
      <div className='container'>
        <LazyLoadImage src={RubyCompany} alt='ruby'/>
      </div>
      <div className='container'>
        <LazyLoadImage src={OvenCompany} alt='ruby' />
      </div>
      <div className='container'>
        <LazyLoadImage src={OrganicCompany} alt='ruby' />
      </div>
      <div className='container'>
        <LazyLoadImage src={JivaCompany} alt='ruby' />
      </div>
      <div className='container'>
        <LazyLoadImage src={BibaCompany} alt='ruby' />
      </div>
      <div className='container'>
        <LazyLoadImage src={BehrouzCompany} alt='ruby' />
      </div>
    </Slider>
    <div className='slider-button' onClick={navigateToContactComponent} style={{cursor:"pointer"}}>Get Started</div>
    </div>
  )
}

export default TrustedCompany