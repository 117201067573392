import React, { useState, useEffect } from 'react';
import './PersonalInfoForm.css';

function PersonalInfoForm({ onNext, initialData }) {
  // Retrieve data from session storage or initialize with default data
  const initialFormData = initialData || JSON.parse(sessionStorage.getItem('personalInfoFormData')) || {
    fullName: '',
    title: '',
    email: '',
    phone: '',
    location: '',
    linkedIn: '',
    github: '',
    description: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(formData);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  // Save form data to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem('personalInfoFormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h1 className='headingName'>Personal Details</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div>
            <label htmlFor="fullName">Full Name:</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your Full Name"
              autoComplete="name"
              required
            />
          </div>
        </div>
        <div className='input-group'>
          <div>
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter your Title Ex:- Frontend Developer/ Software Developer"
              autoComplete="organization-title"
              required
            />
          </div>
        </div>
        <div className="input-group">
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your E-Mail [xyz@gmail.com]"
              autoComplete="email"
              required
            />
          </div>
        </div>
        <div className='input-group'>
          <div>
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your Phone No [+91 1234567890]"
              autoComplete="tel"
            />
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="Enter your Current Location"
            autoComplete="address-level2"
          />
        </div>
        <h2>Social Links</h2>
        <div className="social-links">
          <div>
            <label htmlFor="linkedIn">LinkedIn:</label>
            <input
              type="text"
              id="linkedIn"
              name="linkedIn"
              value={formData.linkedIn}
              onChange={handleChange}
              placeholder="https://linkedin.com"
              autoComplete="url"
            />
          </div>
          <div>
            <label htmlFor="github">GitHub:</label>
            <input
              type="text"
              id="github"
              name="github"
              value={formData.github}
              onChange={handleChange}
              placeholder="https://github.com"
              autoComplete="url"
            />
          </div>
        </div>
        <div className="objective-container">
          <h2>Objective</h2>
          <div className="input-group">
            <label htmlFor="description">Summary:</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              maxLength={300}
              placeholder="Enter your Summary profile in maximum 300 words"
              required
            ></textarea>
            <span className="description-counter">{formData.description.length}/300</span>
          </div>
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
}

export default PersonalInfoForm;