import React, { useState, useEffect } from "react";

function EducationForm({ onNext, onPrevious, education }) {
  const initialFormData = JSON.parse(sessionStorage.getItem("education")) || [
    {
      id: 1,
      courseName: "",
      stream: "",
      collegeName: "",
      cgpaPercent: "",
      startDate: "",
      endDate: "",
    },
  ];

  const [formData, setFormData] = useState(education || initialFormData);
  const [nextId, setNextId] = useState(initialFormData.length + 1); // Initial ID for next education entry

  useEffect(() => {
    sessionStorage.setItem("education", JSON.stringify(formData)); // Store data in session storage
  }, [formData]);

  const handleInputChange = (id, event) => {
    const { name, value } = event.target;
    const newEducation = formData.map((edu) =>
      edu.id === id ? { ...edu, [name]: value } : edu
    );
    setFormData(newEducation);
  };

  const handleAddEducation = () => {
    setFormData([
      ...formData,
      {
        id: nextId,
        courseName: "",
        stream: "",
        collegeName: "",
        cgpaPercent: "",
        startDate: "",
        endDate: "",
      },
    ]);
    setNextId(nextId + 1);
  };

  const handleRemoveEducation = (id) => {
    const updatedEducation = formData.filter((edu) => edu.id !== id);
    setFormData(updatedEducation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(formData);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Education Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {formData.map((edu, index) => (
          <div key={edu.id}>
            <div className="input-group">
              <label htmlFor={`courseName${edu.id}`}>Course:</label>
              <input
                type="text"
                id={`courseName${edu.id}`}
                name="courseName"
                value={edu.courseName}
                onChange={(e) => handleInputChange(edu.id, e)}
                placeholder="Enter Course Name [ex:- B.Tech]"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`stream${edu.id}`}>Stream:</label>
              <input
                type="text"
                id={`stream${edu.id}`}
                name="stream"
                value={edu.stream}
                onChange={(e) => handleInputChange(edu.id, e)}
                placeholder="Enter your Stream [ex:- Computer Science]"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`collegeName${edu.id}`}>College Name:</label>
              <input
                type="text"
                id={`collegeName${edu.id}`}
                name="collegeName"
                value={edu.collegeName}
                onChange={(e) => handleInputChange(edu.id, e)}
                placeholder="Your College Name, can include location also"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`cgpaPercent${edu.id}`}>CGPA/Percentage:</label>
              <input
                type="text"
                id={`cgpaPercent${edu.id}`}
                name="cgpaPercent"
                value={edu.cgpaPercent}
                onChange={(e) => handleInputChange(edu.id, e)}
                placeholder="Your avg CGPA or percentage"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`startDate${edu.id}`}>Start Date:</label>
              <input
                type="number"
                id={`startDate${edu.id}`}
                name="startDate"
                value={edu.startDate}
                onChange={(e) => handleInputChange(edu.id, e)}
                placeholder="Education start year [ex:- 2020]"
                required
                min="1900"
                max="2100"
                step="1"
              />
            </div>
            <div className="input-group">
              <label htmlFor={`endDate${edu.id}`}>End Date:</label>
              <input
                type="number"
                id={`endDate${edu.id}`}
                name="endDate"
                value={edu.endDate}
                onChange={(e) => handleInputChange(edu.id, e)}
                placeholder="Education end year [ex:- 2024]"
                required
                min="1900" // Set the minimum year you want to allow
                max="2100" // Set the maximum year you want to allow
                step="1" // Set the step to 1 for integer values
              />
            </div>
            <button type="button" onClick={() => handleRemoveEducation(edu.id)} className="removeButton">
              Remove
            </button>
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddEducation}>Add Education</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default EducationForm;