import React, { useState, useEffect } from 'react';

function SkillsForm({ onNext, onPrevious, skill }) {
  const initialSkills = JSON.parse(sessionStorage.getItem('skills')) || [{ name: '', value: '' }];

  const [skills, setSkills] = useState(skill || initialSkills);

  useEffect(() => {
    sessionStorage.setItem('skills', JSON.stringify(skills));
  }, [skills]);

  const handleAddSkills = () => {
    setSkills([...skills, { name: '', value: '' }]);
  };

  const handleRemoveSkills = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSkills = [...skills];
    updatedSkills[index][name] = value;
    setSkills(updatedSkills);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(skills);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Skill Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {skills.map((skill, index) => (
          <div key={index}>
            <div className="input-group">
              <label htmlFor={`skillName${index}`}>Skill Name:</label>
              <input
                type="text"
                id={`skillName${index}`}
                name="name"
                value={skill.name}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter skill heading [Ex:- Programming Language/ Frontend Technology]"
                autoComplete='off'
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`skillValue${index}`}>Skill Value:</label>
              <input
                type="text"
                id={`skillValue${index}`}
                name="value"
                value={skill.value}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter value [Ex:- JAVA, Python] comma separated"
                autoComplete='off'
                required
              />
            </div>
            {index > 0 && (
              <button type="button" onClick={() => handleRemoveSkills(index)} className="removeButton">Remove</button>
            )}
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddSkills}>Add Skill</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default SkillsForm;


