import React, { useState, useEffect } from 'react';
import SocialForm from "../../SocialForm/SocialForm";

const Independence = () => {
  const [isFormVisible, setIsFormVisible] = useState(true);

  const handleNext = () => {
    setIsFormVisible(false);
  };

  useEffect(() => {
    if (!isFormVisible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  }, [isFormVisible]);

  return (
    <div>
      {isFormVisible ? (
        <SocialForm onNext={handleNext} />
      ) : (
        <div className='templates-container'>
          <h2 style={{color:"#ffffff", textAlign:"center"}}>Coming Soon</h2>
        </div>
      )}
    </div>
  );
};

export default Independence;
