import React, { useState, useEffect } from 'react';
import SocialForm from "../../SocialForm/SocialForm";
import RakshaBhandan1 from '../../SocialTemplates/RakshaBhandan/RakshaBhandan1';
import RakshaBhandan2 from '../../SocialTemplates/RakshaBhandan/RakshaBhandan2';
import RakshaBhandan3 from '../../SocialTemplates/RakshaBhandan/RakshaBhandan3';
import RakshaBhandan4 from '../../SocialTemplates/RakshaBhandan/RakshaBhandan4';
import { useAuth } from '../../../Registration/AuthContext';
import AuthPopup from '../../../Registration/AuthPopup';

const RakshaBhandan = () => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false); // State to manage login popup
  const { user } = useAuth(); // Get the login state from the context

  const handleNext = () => {
    setIsFormVisible(false);
  };

  useEffect(() => {
    if (!isFormVisible) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  }, [isFormVisible]);

  // Function to close the login popup
  const closeLoginPopup = () => {
    setIsLoginPopupOpen(false);
  };

  // Function to open the login popup
  const openLoginPopup = () => {
    setIsLoginPopupOpen(true);
  };

  const templates = [
    { id: 1, component: <RakshaBhandan1 /> },
    { id: 2, component: <RakshaBhandan2 /> },
    { id: 3, component: <RakshaBhandan3 />, requiresLogin: true },
    { id: 4, component: <RakshaBhandan4 />, requiresLogin: true },
  ];

  return (
    <div>
      {isFormVisible ? (
        <SocialForm onNext={handleNext} />
      ) : (
        <div className='templates-container'>
          <h2 className='template-header'>Select a Template</h2>
          <div className='templates-alignment'>
            {templates.map((template) => (
              <div key={template.id} className='template-wrapper'>
                <div className={`template ${!user && template.requiresLogin ? 'blurFeature' : ''}`}>
                  {template.component}
                </div>
                {!user && template.requiresLogin && (
                  <button className="login-button" onClick={openLoginPopup}>
                    Login
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {isLoginPopupOpen && <AuthPopup onClose={closeLoginPopup} />}
    </div>
  );
};

export default RakshaBhandan;
