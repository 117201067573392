import React, { useState, useEffect } from 'react';

const BusinessForm = ({ onNext }) => {
  const [businessData, setBusinessData] = useState({
    businessName: '',
    tagline: '',
    contactNumber: '',
    emailAddress: '',
    businessAddress: '',
    siteLink: '',
    offerPercentage: '', // New state for offer percentage
    logo: null, // To store the file object of the logo
  });

  useEffect(() => {
    // Initialize form fields with values from session storage if they exist
    const storedBusinessName = sessionStorage.getItem('businessName');
    const storedTagline = sessionStorage.getItem('tagline');
    const storedcontactNumber = sessionStorage.getItem('contactNumber');
    const storedEmailAddress = sessionStorage.getItem('emailAddress');
    const storedBusinessAddress = sessionStorage.getItem('businessAddress');
    const storedSiteLink = sessionStorage.getItem('siteLink');
    const storedOfferPercentage = sessionStorage.getItem('offerPercentage');

    setBusinessData({
      businessName: storedBusinessName || '',
      tagline: storedTagline || '',
      contactNumber: storedcontactNumber || '',
      emailAddress: storedEmailAddress || '',
      businessAddress: storedBusinessAddress || '',
      siteLink: storedSiteLink || '',
      offerPercentage: storedOfferPercentage || '', // Initialize with session storage value
      logo: null, // Initialize logo state based on your use case
    });
  }, []);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];

    // Store selected logo in session storage as a data URL or other relevant method
    const reader = new FileReader();
    reader.onloadend = () => {
      sessionStorage.setItem('logo', reader.result); // Store as needed
      setBusinessData({ ...businessData, logo: file });
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBusinessData({
      ...businessData,
      [name]: value,
    });

    // Store each individual field in session storage as it changes
    sessionStorage.setItem(name, value);
  };

  const handleNext = () => {
    onNext(); // Navigate to the next page
  };

  return (
    <div className='form-container'>
      <div className='headingTitle'>
        <div className='headingName'>Business Post</div>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleLogoChange}
        style={{ margin: "20px 0px", fontWeight: "600" }}
      />
      <div>
        <label htmlFor='businessName'>Name:</label>
        <input
          type="text"
          id='businessName'
          name="businessName"
          value={businessData.businessName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor='tagline'>Tagline:</label>
        <input
          type="text"
          id='tagline'
          name="tagline"
          value={businessData.tagline}
          onChange={handleInputChange}
          maxLength={25}
        />
      </div>
      <div>
        <label htmlFor='contactNumber'>Contact Number:</label>
        <input
          type="tel"
          id='contactNumber'
          name="contactNumber"
          value={businessData.contactNumber}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor='emailAddress'>Email Address:</label>
        <input
          type="email"
          id='emailAddress'
          name="emailAddress"
          value={businessData.emailAddress}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label htmlFor='siteLink'>Website Link:</label>
        <input
          type="url"
          id='siteLink'
          name="siteLink"
          value={businessData.siteLink}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label htmlFor='offerPercentage'>Offer Percentage:</label>
        <input
          type="number"
          id='offerPercentage'
          name="offerPercentage"
          value={businessData.offerPercentage}
          onChange={handleInputChange}
          min="0"
          max="100"
        />
      </div>
      <div>
        <label htmlFor='businessAddress'>Address:</label>
        <textarea
          name="businessAddress"
          id='businessAddress'
          value={businessData.businessAddress}
          onChange={handleInputChange}
          maxLength={30}
        />
        <span className="description-counter">{businessData.businessAddress.length}/30</span>
      </div>
      <div style={{ margin: "20px 0px", fontSize: "0.7em" }}>
        <span style={{ color: "red" }}>Just a friendly reminder: </span>
        Please upload a transparent logo.
      </div>
      <div className='business-buttons'>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default BusinessForm;
