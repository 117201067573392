import React from 'react';

const OtherPost = () => {

  return (
    <div>
        <div className='templates-container'>
          <h2 style={{color:"#ffffff", textAlign:"center"}}>Coming Soon</h2>
        </div>
    </div>
  );
};

export default OtherPost;
