import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import ResumeAiTool from "./AIasset/ResumeAiTool.webp"
import SocialMediaAiTool from "./AIasset/SocialMediaAiTool.webp";
import "./AiTool.css";

const Card2 = ({ number, link, imgSrc, title, description, date, tags }) => {
  return (
    <div className="ai-card-space">
      <Link className="ai-card" to={link} style={{ '--bg-img': `url(${imgSrc})` }}>
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="ai-date">{date}</div>
          <div className="ai-tags">
            {tags.map(tag => (
              <div className="tag" key={tag}>{tag}</div>
            ))}
          </div>
        </div>
      </Link>
    </div>
  );
};

const CardsWrapper2 = () => {
  const cardsData2 = [
    {
      number:1,
      link: '/resume',
      imgSrc: ResumeAiTool,
      title: 'Resume',
      description: 'Create a professional resume in minutes with our easy-to-use resume generator!',
      tags: ['Next']
    },
    {
      number:2,
      link: '/socialAi',
      imgSrc: SocialMediaAiTool,
      title: 'Social Media',
      description: 'Leading the way in innovation and excellence, committed to your business success and growth.',
      tags: ['Next']
    }
  ];

  return (
    <section className="ai-cards-wrapper">
      {cardsData2.map(card2 => (
        <Card2 key={card2.number} {...card2} />
      ))}
    </section>
  );
};

const AiTools = () => {
  useEffect(() => {
    document.title = "Techealer AI Tools";
  }, []);
  return (
    <div>
      <CardsWrapper2 />
    </div>
  );
};

export default AiTools;