import React, { useState } from "react";
import PersonalInfoForm from "../Components/PersonalInfoForm";
import WorkExperienceForm from "../Components/WorkExperienceForm";
import EducationForm from "../Components/EducationForm";
import ProjectForm from "../Components/ProjectForm";
import ProfessionalSkills from "../Components/ProfessionalSkills";
import CertificateForm from "../Components/CertificateForm";
import LanguageForm from "../Components/LanguageForm";
import { FaPhone } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import "./Template4.css";

function Template4() {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [education, setEducation] = useState(null);
  const [projects, setProjects] = useState([]);
  const [professionalSkills, setProfessionalSkills] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showWorkExperienceForm, setShowWorkExperienceForm] = useState(false);
  const [showEducationForm, setShowEducationForm] = useState(false);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showProfessionalSkills, setShowProfessionalSkills] = useState(false);
  const [showCertificateForm, setShowCertificateForm] = useState(false);
  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);

  const handlePersonalInfoNext = (data) => {
    setPersonalInfo(data);
    setShowWorkExperienceForm(true);
  };

  const handleWorkExperienceNext = (data) => {
    setWorkExperiences(data);
    setShowWorkExperienceForm(false);
    setShowEducationForm(true);
  };

  const handleEducationNext = (data) => {
    setEducation(data);
    setShowEducationForm(false);
    setShowProjectForm(true);
  };

  const handleProjectNext = (data) => {
    setProjects(data);
    setShowProjectForm(false);
    setShowProfessionalSkills(true);
  };

  const handleProfessionalSkillsNext = (data) => {
    setProfessionalSkills(data);
    setShowProfessionalSkills(false);
    setShowCertificateForm(true);
  };

  const handleCertificateNext = (data) => {
    setCertificate(data);
    setShowCertificateForm(false);
    setShowLanguageForm(true);
  };

  const handleLanguageNext = (data) => {
    setLanguage(data);
    setShowLanguageForm(false);
    setShowTemplate(true);
  };
  
  const handleWorkExperiencePrevious = () => {
    setShowWorkExperienceForm(false);
  };

  const handleEducationPrevious = () => {
    setShowWorkExperienceForm(true);
    setShowEducationForm(false);
  };

  const handleProjectPrevious = () => {
    setShowEducationForm(true);
    setShowProjectForm(false);
  };

  const handleProfessionalSkillsPrevious = () => {
    setShowProjectForm(true);
    setShowProfessionalSkills(false);
  };

  const handleCertificatePrevious = () => {
    setShowProfessionalSkills(true);
    setShowCertificateForm(false);
  };

  const handleLanguagePrevious = () => {
    setShowCertificateForm(true);
    setShowLanguageForm(false);
  };

  const hideButtonAndPrint = () => {
    var button = document.getElementById("downloadButton");
    button.classList.add("hidden");
    window.print();
  }

  return (
    <div>
      {!showWorkExperienceForm && !showEducationForm && !showProjectForm && !showProfessionalSkills && !showCertificateForm && !showLanguageForm && !showTemplate && (
        <div>
          <PersonalInfoForm onNext={handlePersonalInfoNext} />
        </div>
      )}
      {showWorkExperienceForm && !showEducationForm && !showProjectForm && !showProfessionalSkills && !showCertificateForm && !showLanguageForm && !showTemplate && (
        <div>
          <WorkExperienceForm onNext={handleWorkExperienceNext} onPrevious={handleWorkExperiencePrevious} />
        </div>
      )}
      {showEducationForm && !showProjectForm && !showProfessionalSkills && !showCertificateForm && !showLanguageForm && !showTemplate && (
        <div>
          <EducationForm onNext={handleEducationNext} onPrevious={handleEducationPrevious} />
        </div>
      )}
      {showProjectForm && !showProfessionalSkills && !showCertificateForm && !showLanguageForm && !showTemplate && (
        <div>
          <ProjectForm onNext={handleProjectNext} onPrevious={handleProjectPrevious} />
        </div>
      )}
      {showProfessionalSkills && !showCertificateForm && !showLanguageForm && !showTemplate && (
        <div>
          <ProfessionalSkills onNext={handleProfessionalSkillsNext} onPrevious={handleProfessionalSkillsPrevious} />
        </div>
      )}
      {showCertificateForm && !showLanguageForm && !showTemplate && (
        <div>
          <CertificateForm onNext={handleCertificateNext} onPrevious={handleCertificatePrevious} />
        </div>
      )}
      {showLanguageForm && !showTemplate && (
        <div>
          <LanguageForm onNext={handleLanguageNext} onPrevious={handleLanguagePrevious} />
        </div>
      )}
      
      <div>
        {showTemplate && (
          <div>
            <div style={{paddingLeft: '30px'}}>
              <button id="downloadButton" onClick={hideButtonAndPrint}>DOWNLOAD</button>
            </div>
          <div className="container4">
            {personalInfo && (
              <div>
                <div className="profile4">
                    <div className="bracket">
                        <div className="personalName4">{personalInfo.fullName}</div>
                        <div className="location4">{personalInfo.title}{", "}{personalInfo.location}</div>
                        {personalInfo.description && <div className="description4">{personalInfo.description}</div>}
                    </div>
                    <div className="details4">
                        {personalInfo.phone && (<div className="contact4"><div><FaPhone size="14px" style={{ marginRight: "4px" }} /></div><div>{personalInfo.phone}{" "}</div></div>)}
                        {personalInfo.email && (<div className="contact4"><div><MdMail size="16px" style={{ marginRight: "4px" }} /></div><div>{personalInfo.email}{" "}</div></div>)}
                        {personalInfo.linkedIn && (<div className="contact4"><div><FaLinkedin size="16px" style={{ marginRight: "4px" }} /></div><div>{personalInfo.linkedIn}{" "}</div></div>)}
                        {personalInfo.github && (<div className="contact4"><div><FaGithub size="16px" style={{ marginRight: "4px" }} /></div><div>{personalInfo.github}</div></div>)}
                  </div>
                </div>
              </div>
            )}

                {professionalSkills.length > 0 && (
                    <div>
                        <div className="headingText4">Professional Skills</div>
                        <div className="skill4">
                            {professionalSkills.map((professionalSkill, index) => (
                                <div key={index}>
                                    <div className="skillAlignValue4">{professionalSkill.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}       

            {workExperiences.length > 0 && (
              <div>
                <div className="headingText4">Work Experience</div>
                {workExperiences.map((experience, index) => (
                  <div key={index}>
                    <div className="align4">
                      <div className="companyName4">{experience.companyName}</div>
                      <div className="date4">{experience.startDate}{" - "}{experience.endDate}</div>
                    </div>
                    <div className="align4">
                      <div className="positionCompany4">{experience.position}</div>
                      {experience.location && <div className="jobLocation4">{experience.location}</div>}
                    </div>
                    <ul className="descriptionJob4">
                      {experience.jobDescription && <li>{experience.jobDescription}</li>}
                      {experience.additionalField1 && <li>{experience.additionalField1}</li>}
                      {experience.additionalField2 && <li>{experience.additionalField2}</li>}
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {projects.length > 0 && (
              <div>
                <div className="headingText4">Projects</div>
                {projects.map((project, index) => (
                  <div key={index}>
                    <div className="align4">
                      <div className="projectName4">{project.projectName}</div>
                      <div className="projectYear4">{project.completionYear}</div>
                    </div>
                    <div className="align4">
                      <div className="techStack4">{project.techStack}</div>
                      {project.githubLink && <div className="projectLink4">{project.githubLink}</div>}
                    </div>
                    <ul className="projectDescription4">
                      {project.projectDescription && <li>{project.projectDescription}</li>}
                      {project.additionalField1 && <li>{project.additionalField1}</li>}
                      {project.additionalField2 && <li>{project.additionalField2}</li>}
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {education.length>0 && (
              <div>
                <div className="headingText4">Education</div>
                {education.map((education, index) => (
                  <div key={index}>
                    <div className="align4">
                      <div className="college4">{education.collegeName}</div>
                      <div className="eduPeriod4">{education.startDate}{"-"}{education.endDate}</div>
                    </div>
                    <div className="align4">
                      <div className="course4">{education.courseName} {" in "}{education.stream}</div>
                      {education.cgpaPercent && <div className="cgpa4">{education.cgpaPercent} {" CGPA"}</div>}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {certificate.length > 0 && (
              <div>
                <div className="headingText4">Certificate</div>
                {certificate.map((certificate, index) => (
                  <div key={index}>
                    <ul className="certificateColumn4">
                      <li><span className="certificateName4">{certificate.name}</span>{" - "} {certificate.description}</li>
                    </ul>
                  </div>
                ))}
              </div>
            )}

            {language.length > 0 && (
              <div>
                <div className="headingText4">Language</div>
                <ul className="language4">
                {language.map((language, index) => (
                  <div key={index}>
                      <li className="languageName4">{language.name}</li>
                  </div>
                ))}
                </ul>
              </div>
            )}
          </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Template4;