import React, { useState } from 'react';

const ProfilePhotoUpload = ({ onNext, onPrevious }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    console.log('Selected File:', file); // Check if the file is captured correctly
    setSelectedPhoto(file);
  
    // Store selected photo in session storage
    sessionStorage.setItem('selectedPhoto', JSON.stringify(file));
  };

  const handleNext = () => {
    onNext(selectedPhoto);
  };

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <div className='form-container'>
      <div className='headingTitle'>
        <h2 className='headingName'>Profile Photo</h2>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handlePhotoChange}
        required
      />
      {selectedPhoto && (
        <div>
          <p>Selected Photo:</p>
          <img src={URL.createObjectURL(selectedPhoto)} alt="Selected Profile" style={{ maxWidth: '200px', maxHeight: '200px' }} />
        </div>
      )}
      <div style={{margin:"20px 0px", fontSize:"0.7em"}}><span style={{color:"red"}}>Just a friendly reminder: </span> Reloading or going back might cause you to lose the uploaded photo, and you'll need to upload it again. Please take care!</div>
      <div className='buttons'>
      <button onClick={handlePrevious}>Previous</button>
      <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default ProfilePhotoUpload;
