import React, { useState, useEffect } from 'react';

function InterestForm({ onNext, onPrevious, interest }) {
  // Retrieve data from session storage or initialize with default data
  const initialInterests = JSON.parse(sessionStorage.getItem('interests')) || [{ name: '' }];

  const [interests, setInterests] = useState(interest || initialInterests);

  useEffect(() => {
    sessionStorage.setItem('interests', JSON.stringify(interests)); // Store data in session storage
  }, [interests]);

  const handleAddInterests = () => {
    setInterests([...interests, { name: '' }]);
  };

  const handleRemoveInterests = (index) => {
    const updatedInterests = [...interests];
    updatedInterests.splice(index, 1);
    setInterests(updatedInterests);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedInterests = [...interests];
    updatedInterests[index][name] = value;
    setInterests(updatedInterests);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(interests);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Interest Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {interests.map((interest, index) => (
          <div key={index}>
            <div className="input-group">
              <label htmlFor={`interestName${index}`}>Interest:</label>
              <input
                type="text"
                id={`interestName${index}`}
                name="name"
                value={interest.name}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter your Hobbies [Ex:- Dancing]"
                autoComplete='interests'
                required
              />
            </div>
              <button type="button" onClick={() => handleRemoveInterests(index)} className='removeButton'>Remove</button>
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddInterests}>Add Interest</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default InterestForm;

