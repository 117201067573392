import React, { useState, useEffect } from 'react';

const SocialForm = ({ onNext }) => {
  const [companyData, setCompanyData] = useState({
    companyName: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    emailAddress: '',
    companyAddress: '',
  });

  useEffect(() => {
    // Initialize form fields with values from session storage if they exist
    const storedCompanyName = sessionStorage.getItem('companyName');
    const storedPhoneNumber = sessionStorage.getItem('phoneNumber');
    const storedAlternatePhoneNumber = sessionStorage.getItem('alternatePhoneNumber');
    const storedEmailAddress = sessionStorage.getItem('emailAddress');
    const storedCompanyAddress = sessionStorage.getItem('companyAddress');

    setCompanyData({
      companyName: storedCompanyName || '',
      phoneNumber: storedPhoneNumber || '',
      alternatePhoneNumber: storedAlternatePhoneNumber || '',
      emailAddress: storedEmailAddress || '',
      companyAddress: storedCompanyAddress || '',
    });
  }, []);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];

    // Store selected photo in session storage as a data URL
    const reader = new FileReader();
    reader.onloadend = () => {
      sessionStorage.setItem('selectedPhoto', reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCompanyData({
      ...companyData,
      [name]: value,
    });

    // Store each individual field in session storage as it changes
    sessionStorage.setItem(name, value);
  };

  const handleNext = () => {
    onNext(); // Navigate to the next page
  };

  return (
    <div className='form-container'>
      <div className='headingTitle'>
        <div className='headingName'>Details for Social Post</div>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handlePhotoChange}
        style={{margin:"20px 0px", fontWeight:"600"}}
      />
      <div>
        <label htmlFor='companyName'>Company Name:</label>
        <input
          type="text"
          id='companyName'
          name="companyName"
          value={companyData.companyName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor='phoneNumber'>Phone Number:</label>
        <input
          type="tel"
          id='phoneNumber'
          name="phoneNumber"
          value={companyData.phoneNumber}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label htmlFor='alternatePhoneNumber'>Alternate Phone Number:</label>
        <input
          type="tel"
          id='alternatePhoneNumber'
          name="alternatePhoneNumber"
          value={companyData.alternatePhoneNumber}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label htmlFor='emailAddress'>Email Address:</label>
        <input
          type="email"
          id='emailAddress'
          name="emailAddress"
          value={companyData.emailAddress}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label htmlFor='companyAddress'>Address of Company:</label>
        <textarea
          name="companyAddress"
          id='companyAddress'
          value={companyData.companyAddress}
          onChange={handleInputChange}
          maxLength={30}
        />
        <span className="description-counter">{companyData.companyAddress.length}/30</span>
      </div>
      <div style={{margin:"20px 0px", fontSize:"0.7em"}}>
        <span style={{color:"red"}}>Just a friendly reminder: </span> 
        Please upload transparent logo.
      </div>
      <div className='buttons'>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};
export default SocialForm;