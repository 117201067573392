import React, {useEffect} from "react";
import patentImage1 from "./PatentAsset/patent1.webp";
import "./Patent.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Patent = () => {
  useEffect(() => {
    document.title = "Techealer Patents: Your Idea's Future";
  }, []);
  
  return (
    <div>
      <div className="root-patent patent-container">
        <div className="patentLeft">
          <div className="patent-image">
            <LazyLoadImage src={patentImage1} alt="patent" className="patentImage1" />
          </div>
        </div>
        <div className="patentRight">
          <div className="root-patent patentInfo">
            <h1 className="patentInfo-heading">Patent Services</h1>
            <div className="patentInfo-description">We offer end-to-end patent services, from analysis and filing to portfolio management and addressing challenges. Trust us for precise drafting, navigating complexities, and providing insightful reports.</div>
            <div className="patentInfo-child">
              <div>Patent Filing</div>
              <div>Infringement Search</div>
              <div>Patent Invalidation</div>
              <div>Amazon Patent Search</div>
              <div>Office Action</div>
              <div>Patent Drafting</div>
              <div>Patent Docketing</div>
            </div>
          </div>
        </div>
      </div>
      <div className="patentFeatures">
        <div>
          <h2 className="feature-heading">FEATURES AND PLANS</h2>
        </div>
        <div>
          <div className="feature-table">
            <table style={{ width: "100%" }}>
              <thead>
                <tr className="col-width-height">
                    <th style={{borderLeftColor:'white', borderTopColor:'white'}} className="col-width-height"></th>
                    <th className="col-width-height">USA</th>
                    <th className="col-width-height">Europe</th>
                    <th className="col-width-height">India</th>
                </tr>
              </thead>
              <tbody>
                <tr className="col-width-height">
                    <td style={{backgroundColor:'#d9f7e4'}} className="col-width-height table-cl1">Filling Fees</td>
                    <td className="col-width-height">$400 - $2000 <br />+ USPTO fees</td>
                    <td className="col-width-height">$600 - $2000 <br />+ EPO fees</td>
                    <td className="col-width-height">Rs 10000 + IPO fees</td>
                </tr>
                <tr className="col-width-height">
                    <td style={{backgroundColor:'#d9f7e4'}} className="col-width-height table-cl1">Docketing</td>
                    <td className="col-width-height">$50 monthly</td>
                    <td className="col-width-height">$50 monthly</td>
                    <td className="col-width-height">Rs 2000 monthly</td>
                </tr>
                <tr className="col-width-height">
                    <td style={{backgroundColor:'#d9f7e4'}} className="col-width-height table-cl1">Documentation</td>
                    <td className="col-width-height">$300 to $3000</td>
                    <td className="col-width-height">$300 to $3000</td>
                    <td className="col-width-height">Rs 5000 to 40000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patent;