import React from "react";
import { useNavigate } from 'react-router';
import "./ReliableSupport.css";
import Video from "./Finance-Asset/reliableSupportVideo.mp4";

const ReliableSupport = () => {
    const history = useNavigate();
  const navigateToContactComponent = () => {
    history('/contact');
  };
    return (
        <div className="reliable-support">
            <div className="reliable-parts">
                <div className="reliable-left">
                    <h2 className="reliable-left-heading">Round-the-Clock Finance Assistance: Personalized and Reliable</h2>
                    <div className="reliable-left-description">Access 24/7 personalized support for all your financial needs, ensuring guidance and assistance whenever you require it, empowering you to make informed decisions and achieve your financial goals seamlessly.</div>
                    <div className="reliable-left-learn" onClick={navigateToContactComponent} style={{cursor:"pointer"}}>Learn More</div>
                </div>
                <div className="reliable-right">
                    <div><video autoPlay muted loop src={Video} type="video/mp4" width="100%" height="550px" style={{objectFit:"cover"}} className="reliable-video-style" /></div>
                </div>
            </div>
        </div>
    )
}

export default ReliableSupport;