import React, { useState, useEffect } from 'react';

function ProjectForm({ onNext, onPrevious }) {
  const initialProjects = JSON.parse(sessionStorage.getItem('projects')) || [
    {
      projectName: '',
      techStack: '',
      completionYear: '',
      githubLink: '',
      projectDescription: '',
      additionalField1: '',
      additionalField2: ''
    }
  ];

  const [projects, setProjects] = useState(initialProjects);

  useEffect(() => {
    sessionStorage.setItem('projects', JSON.stringify(projects));
  }, [projects]);

  const handleAddProject = () => {
    setProjects([...projects, {
      projectName: '',
      techStack: '',
      completionYear: '',
      githubLink: '',
      projectDescription: '',
      additionalField1: '',
      additionalField2: ''
    }]);
  };

  const handleRemoveProject = (index) => {
    const updatedProjects = [...projects];
    updatedProjects.splice(index, 1);
    setProjects(updatedProjects);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProjects = [...projects];
    updatedProjects[index][name] = value;
    setProjects(updatedProjects);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(projects);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Project Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {projects.map((project, index) => (
          <div key={index}>
            <h3>Project {index + 1}</h3>
            <div className="input-group">
              <label htmlFor={`projectName${index}`}>Project Name:</label>
              <input
                type="text"
                id={`projectName${index}`}
                name="projectName"
                value={project.projectName}
                onChange={(e) => handleChange(e, index)}
                placeholder="Enter your Project Title"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`techStack${index}`}>Tech Stack:</label>
              <input
                type="text"
                id={`techStack${index}`}
                name="techStack"
                value={project.techStack}
                onChange={(e) => handleChange(e, index)}
                placeholder="Enter your stack used to make project [ex:- MERN Stack]"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`completionYear${index}`}>Completion Year:</label>
              <input
                type="text"
                id={`completionYear${index}`}
                name="completionYear"
                value={project.completionYear}
                onChange={(e) => handleChange(e, index)}
                placeholder="Project Completion Year [Ex:- 2022]"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`githubLink${index}`}>GitHub Link:</label>
              <input
                type="text"
                id={`githubLink${index}`}
                name="githubLink"
                value={project.githubLink}
                onChange={(e) => handleChange(e, index)}
                placeholder="Enter your project github link"
              />
            </div>
            <div className="input-group">
              <label htmlFor={`projectDescription${index}`}>Project Description:</label>
              <input
                type="text"
                id={`projectDescription${index}`}
                name="projectDescription"
                value={project.projectDescription}
                onChange={(e) => handleChange(e, index)}
                placeholder="Describe your project [point 1]"
                required
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                id={`additionalField1${index}`}
                name="additionalField1"
                value={project.additionalField1}
                onChange={(e) => handleChange(e, index)}
                placeholder="Project Description [point 2] / not required"
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                id={`additionalField2${index}`}
                name="additionalField2"
                value={project.additionalField2}
                onChange={(e) => handleChange(e, index)}
                placeholder="Project Description [point 3] / not required"
              />
            </div>
            {index > 0 && (
              <button type="button" onClick={() => handleRemoveProject(index)} className="removeButton" >Remove</button>
            )}
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddProject}>Add Project</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default ProjectForm;
