import React, { useState } from 'react';
import './SipCalculator.css';

const SipCalculator = () => {
    const [monthlyInvestment, setMonthlyInvestment] = useState(1000);
    const [annualInterestRate, setAnnualInterestRate] = useState(10);
    const [investmentDuration, setInvestmentDuration] = useState(5);
    const [result, setResult] = useState(null);
    const [investedAmount, setInvestedAmount] = useState(null);
    const [estimatedReturn, setEstimatedReturn] = useState(null);

    const calculateSIP = (e) => {
        e.preventDefault();
        const P = parseFloat(monthlyInvestment);
        const r = parseFloat(annualInterestRate) / 100 / 12;
        const n = parseFloat(investmentDuration) * 12;

        const maturityValue = P * (((1 + r) ** n - 1) / r) * (1 + r);
        const totalInvested = P * n;
        const estReturn = maturityValue - totalInvested;

        setResult(maturityValue.toFixed(2));
        setInvestedAmount(totalInvested.toFixed(2));
        setEstimatedReturn(estReturn.toFixed(2));
    };

    return (
        <div className="sip-calculator">
            <h2 className='sip-heading'>SIP Calculator</h2>
            <form onSubmit={calculateSIP} className='sip-form'>
                <div className="sip-input-group">
                    <label htmlFor="monthlyInvestment" className='sip-input-label'>Monthly Investment (₹): </label>
                    <div className="sip-input-controls">
                        <input id="monthlyInvestment" type="number" value={monthlyInvestment} onChange={(e) => setMonthlyInvestment(e.target.value)} className="sip-input-box" />
                        <input id="monthlyInvestmentRange" type="range" min="500" max="100000" step="100" value={monthlyInvestment} onChange={(e) => setMonthlyInvestment(e.target.value)} className="slider" />
                    </div>
                </div>
                <div className="sip-input-group">
                    <label htmlFor="annualInterestRate" className='sip-input-label'>Annual Interest Rate (%): </label>
                    <div className="sip-input-controls">
                        <input
                            id="annualInterestRate"
                            type="number"
                            value={annualInterestRate}
                            onChange={(e) => setAnnualInterestRate(e.target.value)}
                            className="sip-input-box"
                        />
                        <input
                            id="annualInterestRateRange"
                            type="range"
                            min="1"
                            max="30"
                            step="0.1"
                            value={annualInterestRate}
                            onChange={(e) => setAnnualInterestRate(e.target.value)}
                            className="slider"
                        />
                    </div>
                </div>
                <div className="sip-input-group">
                    <label htmlFor="investmentDuration" className='sip-input-label'>Investment Duration (years): </label>
                    <div className="sip-input-controls">
                        <input
                            id="investmentDuration"
                            type="number"
                            value={investmentDuration}
                            onChange={(e) => setInvestmentDuration(e.target.value)}
                            className="sip-input-box"
                        />
                        <input
                            id="investmentDurationRange"
                            type="range"
                            min="1"
                            max="30"
                            step="1"
                            value={investmentDuration}
                            onChange={(e) => setInvestmentDuration(e.target.value)}
                            className="slider"
                        />
                    </div>
                </div>
                <button type="submit" className="sip-calculate-button">Calculate</button>
            </form>
            {result && (
                <div className="sip-result">
                    <h3>Maturity Value: ₹{result}</h3>
                    <p className='sip-result-p'>Invested Amount: ₹{investedAmount}</p>
                    <p className='sip-result-p'>Estimated Return: ₹{estimatedReturn}</p>
                </div>
            )}
        </div>
    );
};

export default SipCalculator;