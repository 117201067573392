import React, { useState } from "react";
import './LoginPopup.css';
import './style.css';
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from './firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInWithGoogle from './signInWithGoogle';

const SignUpForm = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [uname, setUname] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      console.log(user);
      if (user) {
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          userName: uname,
        });
      }
      console.log("User Registered Successfully!!");
      toast.success("Registered Successfully!!", {
        position: "top-center",
      });
      setEmail('');
      setPassword('');
      setUname('');
      onClose(); // Close the pop-up
      navigate('/resume');
    } catch (err) {
      console.log(err.message);
      toast.error(err.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <div className="login-popup">
      <div className="login-popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        <form className="signup-form" onSubmit={handleSubmit}>
          <h2>Sign Up</h2>
          <label htmlFor="first-name">User Name:
            <input type="text" value={uname} placeholder="Full Name" onChange={(e) => setUname(e.target.value)} id="first-name" required autoComplete="username" />
          </label>
          <label htmlFor="email">Email:
            <input type="email" value={email} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} id="email" required autoComplete="email" />
          </label>
          <label htmlFor="password">Password:
            <input type="password" value={password} placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} id="password" autoComplete="off" />
          </label>
          <button type="submit">Sign Up</button>
          <p className="already">Already Registered? <Link to="/login" className="sign-nav" onClick={onClose}>Login</Link></p>
          <SignInWithGoogle />
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
