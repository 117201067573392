import React from 'react';
import { useNavigate } from 'react-router';
import './Occasional.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Ganesh from "./GaneshChaturthi/Ganesh.webp";
import Independence from "./IndependenceDay/Independence.avif";
import Diwali from "./Diwali/Diwali.jpg";
import RBandhan from "./RakshaBhandan/RBandhan.jfif";

const Occasional = () => {
  const history = useNavigate();
  const navigateToDiwaliComponent = () => {
    history('/diwali');
  };
  const navigateToRakshaBhandhanComponent = () => {
    history('/rakshaBhandan');
  };
  const navigateToGaneshChaturthiComponent = () => {
    history('/ganeshChaturthi');
  };
  const navigateToIndependenceDayComponent = () => {
    history('/independence');
  };
  return (
    <ul className="occasional-cards">
      <li>
        <div onClick={navigateToRakshaBhandhanComponent} className="occasional-card">
          <LazyLoadImage src={RBandhan} className="occasional-card__image" alt=""/>
          <div className="occasional-card__overlay">
            <div className="occasional-card__header">
              <svg className="occasional-card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              <div className="occasional-card__header-text">
                <h3 className="occasional-card__title">Raksha Bhandan</h3>
              </div>
            </div>
            <p className="occasional-card__description">Celebrate the bond of love and protection this Raksha Bandhan with our special collections!</p>
          </div>
        </div>
      </li>
      <li>
        <div onClick={navigateToGaneshChaturthiComponent} className="occasional-card">
          <LazyLoadImage src={Ganesh} className="occasional-card__image" alt=""/>
          <div className="occasional-card__overlay">
            <div className="occasional-card__header">
              <svg className="occasional-card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              <div className="occasional-card__header-text">
                <h3 className="occasional-card__title">Ganesh Chaturthi</h3>
              </div>
            </div>
            <p className="occasional-card__description">Embrace the blessings of Ganesh Chaturthi with our exclusive festive offerings!</p>
          </div>
        </div>
      </li>
      <li>
        <div onClick={navigateToIndependenceDayComponent} className="occasional-card">
          <LazyLoadImage src={Independence} className="occasional-card__image" alt=""/>
          <div className="occasional-card__overlay">
            <div className="occasional-card__header">
              <svg className="occasional-card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              <div className="occasional-card__header-text">
                <h3 className="occasional-card__title">Independence Day</h3>
              </div>
            </div>
            <p className="occasional-card__description">Let's honor the valor and sacrifices of our forefathers by striving for a better and united nation.</p>
          </div>
        </div>
      </li>
      <li>
        <div onClick={navigateToDiwaliComponent} className="occasional-card">
          <LazyLoadImage src={Diwali} className="occasional-card__image" alt=""/>
          <div className="occasional-card__overlay">
            <div className="occasional-card__header">
              <svg className="occasional-card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              <div className="occasional-card__header-text">
                <h3 className="occasional-card__title">Diwali</h3>
              </div>
            </div>
            <p className="occasional-card__description">Illuminate your celebrations with our dazzling Diwali collection, radiating joy and prosperity!</p>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default Occasional;