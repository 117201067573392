import React, { useState, useEffect } from 'react';

function ReferenceForm({ onNext, onPrevious }) {
  const [reference, setReference] = useState(() => {
    const savedReference = sessionStorage.getItem('reference');
    return savedReference ? JSON.parse(savedReference) : {
      name: '',
      company: '',
      companySite: '',
      phoneNumber: '',
    };
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReference({ ...reference, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(reference);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    sessionStorage.setItem('reference', JSON.stringify(reference));
  }, [reference]);

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Reference Information</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="name">Reference Name:*</label>
          <input
            type="text"
            id="name"
            name="name"
            value={reference.name}
            onChange={handleInputChange}
            placeholder="Enter reference name"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="company">Referred Company:*</label>
          <input
            type="text"
            id="company"
            name="company"
            value={reference.company}
            onChange={handleInputChange}
            placeholder="Enter referred company"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="companySite">Company Site:</label>
          <input
            type="text"
            id="companySite"
            name="companySite"
            value={reference.companySite}
            onChange={handleInputChange}
            placeholder="Enter company site"
          />
        </div>
        <div className="input-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={reference.phoneNumber}
            onChange={handleInputChange}
            placeholder="Enter phone number"
          />
        </div>
        <div className="buttons">
          <button type="button" onClick={handlePrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default ReferenceForm;
