import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { GiHamburgerMenu, GiCancel } from "react-icons/gi";
import { useAuth } from '../Registration/AuthContext';
import { auth } from '../Registration/firebase';
import { signOut } from "firebase/auth";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const { user } = useAuth(); // Get the user from the context
  const navigate = useNavigate();

  const handleToggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleCloseNavbar = () => {
    setShowNavbar(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success("User logged out successfully!", {
        position: "top-center",
      });
      navigate('/home'); // Redirect to home page after logout
    } catch (err) {
      console.log(err.message);
      toast.error("Failed to log out. Please try again.", {
        position: "top-center",
      });
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <NavLink to="/">Techealer</NavLink>
        </div>
        <div className="navbar-menu-icon" onClick={handleToggleNavbar}>
          {showNavbar ? (
            <GiCancel width="30px" onClick={handleCloseNavbar} style={{ color: "white" }} />
          ) : (
            <GiHamburgerMenu width="30px" style={{ color: "white" }} />
          )}
        </div>
        <div className={`nav-elements ${showNavbar && 'active'}`}>
          <ul className='nav-ul'>
            <li className='nav-links'>
              <NavLink to="/home" className="nav-navigation" onClick={handleCloseNavbar}>Home</NavLink>
            </li>
            <li className='nav-links'>
              <NavLink to="/patent" className="nav-navigation" onClick={handleCloseNavbar}>Patent</NavLink>
            </li>
            <li className='nav-links'>
              <NavLink to="/aiTools" className="nav-navigation" onClick={handleCloseNavbar}>AI Tools</NavLink>
            </li>
            <li className='nav-links'>
              <NavLink to="/brand" className="nav-navigation" onClick={handleCloseNavbar}>Branding</NavLink>
            </li>
            <li className='nav-links'>
              <NavLink to="/website" className="nav-navigation" onClick={handleCloseNavbar}>Website</NavLink>
            </li>
            <li className='nav-links'>
              <NavLink to="/contact" className="nav-navigation" onClick={handleCloseNavbar}>Contact</NavLink>
            </li>
            <li className='nav-links'>
              {user ? (
                <NavLink to="/home" className="nav-navigation" onClick={handleLogout}>Logout</NavLink>
              ) : (
                <NavLink to="/login" className="nav-navigation" onClick={handleCloseNavbar}>Login</NavLink>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
