import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import "./RakshaBhandan1.css";

const RakshaBhandan1 = () => {
  const [formData] = useState({
    companyData: {
      companyName: sessionStorage.getItem('companyName'),
      phoneNumber: sessionStorage.getItem('phoneNumber'),
      alternatePhoneNumber: sessionStorage.getItem('alternatePhoneNumber'),
      emailAddress: sessionStorage.getItem('emailAddress'),
      companyAddress: sessionStorage.getItem('companyAddress'),
    },
    selectedPhoto: sessionStorage.getItem('selectedPhoto')
  });

  useEffect(() => {
    // Scroll to the bottom to capture the entire content
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const downloadImage = async () => {
    const element = document.getElementById('raksha-container1');

    const scale = 3; // Adjust scale for high-DPI screens
    const canvas = await html2canvas(element, { scale: scale });

    // Create a new canvas with the desired dimensions
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = 1080;
    resizedCanvas.height = 1080;
    const context = resizedCanvas.getContext('2d');

    // Draw the original canvas onto the resized canvas, scaling it down
    context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 1080, 1080);

    const data = resizedCanvas.toDataURL('image/jpeg', 1.0);
    const link = document.createElement('a');

    link.href = data;
    link.download = 'downloaded-image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='r-container'>
      <div id='raksha-container1' className='raksha-container1'>
        <div className='r-line1'>
          {formData.selectedPhoto ? (
            <div className='r-logo'>
              <img src={formData.selectedPhoto} alt="Selected Profile" style={{ width: "100%", objectFit: "cover" }} />
            </div>
          ) : (
            <div className='r-companyName'><strong>{formData.companyData.companyName}</strong></div>
          )}
        </div>
        <div className='r-details'>
          <div>{formData.companyData.phoneNumber} {"/"} {formData.companyData.alternatePhoneNumber}</div>
          <p>{formData.companyData.emailAddress}</p>
          <p>{formData.companyData.companyAddress}</p>
        </div>
      </div>
      <div><button onClick={downloadImage} className='r-download'>Download Image</button></div>
    </div>
  );
};

export default RakshaBhandan1;
