import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';
import { AiFillInstagram, AiOutlineGlobal, AiFillLinkedin } from 'react-icons/ai'; // Import icons from react-icons

const Footer = () => {
    return (
        <div className='footer-main-container'>
            <footer className="footer">
                <div className="footer-about">
                    <h2 className='f-company'>Techealer</h2>
                    <p>Techealer InfoTech. Specializing in Patents, Branding, AI Tools, Website Design, Finance, and more. Discover how we can transform your business. Learn more about our comprehensive services and innovative solutions.</p>
                </div>
                <div className="footer-right">
                    <div className='footer-right-part1'>
                        <div className="footer-services">
                            <h3>Services</h3>
                            <p><NavLink to="/patent" className="footer-direction">Patent</NavLink></p>
                            <p><NavLink to="/resume" className="footer-direction">AI Tools</NavLink></p>
                            <p><NavLink to="/brand" className="footer-direction">Branding</NavLink></p>
                            <p><NavLink to="/website" className="footer-direction">Website Designing</NavLink></p>
                            <p><NavLink to="/finance" className="footer-direction">Financial Solutions</NavLink></p>
                            <p><NavLink to="/internship" className="footer-direction">Internship</NavLink></p>
                        </div>
                        <div className="footer-company">
                            <h3>Company</h3>
                            <p><NavLink to="/home" className="footer-direction">Home</NavLink></p>
                            <p><NavLink to="/finance" className="footer-direction">About Us</NavLink></p>
                            <p><NavLink to="/contact" className="footer-direction">Contact Us</NavLink></p>
                            <p><NavLink to="/internship" className="footer-direction">Career</NavLink></p>
                        </div>
                    </div>
                    <div className="footer-get-in-touch">
                        <h3>Get in Touch</h3>
                        <div className="social-icons">
                            <a href="https://www.instagram.com/techealer" target="_blank" rel="noopener noreferrer">
                                <AiFillInstagram className="social-icon" style={{ color: '#E1306C', fontSize: '2rem', marginRight: '10px' }} />
                            </a>
                            <a href="https://techealer.com" target="_blank" rel="noopener noreferrer">
                                <AiOutlineGlobal className="social-icon" style={{ color: '#007BFF', fontSize: '2rem', marginRight: '10px' }} />
                            </a>
                            <a href="https://www.linkedin.com/company/techealer" target="_blank" rel="noopener noreferrer">
                                <AiFillLinkedin className="social-icon" style={{ color: '#0A66C2', fontSize: '2rem', marginRight: '10px' }} />
                            </a>
                        </div>
                        <p>Email: techealer77@gmail.com</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
