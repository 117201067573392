import React, { useState } from "react";
import PersonalInfoForm from "../Components/PersonalInfoForm";
import WorkExperienceForm from "../Components/WorkExperienceForm";
import EducationForm from "../Components/EducationForm";
import ProfessionalSkills from "../Components/ProfessionalSkills";
import LanguageForm from "../Components/LanguageForm";
import ReferenceForm from "../Components/ReferenceForm";
import ProfilePhotoUpload from "../Components/ProfilePhotoUpload";
import { FaPhone } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import "./Template3.css";

function Template3() {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [reference, setReference] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [education, setEducation] = useState(null);
  const [professionalSkills, setProfessionalSkills] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showWorkExperienceForm, setShowWorkExperienceForm] = useState(false);
  const [showEducationForm, setShowEducationForm] = useState(false);
  const [showProfessionalSkills, setShowProfessionalSkills] = useState(false);
  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [showReferenceForm, setShowReferenceForm] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showProfilePhoto, setShowProfilePhoto] = useState(false);

  const handlePersonalInfoNext = (data) => {
    setPersonalInfo(data);
    setShowWorkExperienceForm(true);
  };

  const handleWorkExperienceNext = (data) => {
    setWorkExperiences(data);
    setShowWorkExperienceForm(false);
    setShowEducationForm(true);
  };

  const handleEducationNext = (data) => {
    setEducation(data);
    setShowEducationForm(false);
    setShowProfessionalSkills(true);
  };

  const handleProfessionalSkillsNext = (data) => {
    setProfessionalSkills(data);
    setShowProfessionalSkills(false);
    setShowLanguageForm(true);
  };

  const handleLanguageNext = (data) => {
    setLanguage(data);
    setShowLanguageForm(false);
    setShowReferenceForm(true);
  };

  const handleReferenceNext = (data) => {
    setReference(data);
    setShowReferenceForm(false);
    setShowProfilePhoto(true);
  };

  const handleProfileNext = (data) => {
    setProfilePhoto(data);
    setShowTemplate(true);
  };

  const handleWorkExperiencePrevious = () => {
    setShowWorkExperienceForm(false);
  };

  const handleEducationPrevious = () => {
    setShowWorkExperienceForm(true);
    setShowEducationForm(false);
  };

  const handleProfessionalSkillsPrevious = () => {
    setShowEducationForm(true);
    setShowProfessionalSkills(false);
  };

  const handleLanguagePrevious = () => {
    setShowProfessionalSkills(true);
    setShowLanguageForm(false);
  };

  const handleReferencePrevious = () => {
    setShowLanguageForm(true);
    setShowReferenceForm(false);
  };

  const handlePhotoPrevious = () => {
    setShowReferenceForm(true);
    setShowProfilePhoto(false);
  };

  const hideButtonAndPrint = () => {
    var button = document.getElementById("downloadButton");
    button.classList.add("hidden");
    window.print();
  };

  return (
    <div>
      {!showWorkExperienceForm && !showEducationForm && !showProfessionalSkills && !showLanguageForm && !showReferenceForm && !showTemplate && !showProfilePhoto && (
        <div>
          <PersonalInfoForm onNext={handlePersonalInfoNext} />
        </div>
      )}
      {showWorkExperienceForm && !showEducationForm && !showProfessionalSkills && !showLanguageForm && !showReferenceForm && !showTemplate && !showProfilePhoto && (
        <div>
          <WorkExperienceForm onNext={handleWorkExperienceNext} onPrevious={handleWorkExperiencePrevious} />
        </div>
      )}
      {showEducationForm && !showProfessionalSkills && !showLanguageForm && !showReferenceForm && !showTemplate && !showProfilePhoto && (
        <div>
          <EducationForm onNext={handleEducationNext} onPrevious={handleEducationPrevious} />
        </div>
      )}
      {showProfessionalSkills && !showLanguageForm && !showReferenceForm && !showTemplate && !showProfilePhoto && (
        <div>
          <ProfessionalSkills onNext={handleProfessionalSkillsNext} onPrevious={handleProfessionalSkillsPrevious} />
        </div>
      )}
      {showLanguageForm && !showReferenceForm && !showTemplate && !showProfilePhoto && (
        <div>
          <LanguageForm onNext={handleLanguageNext} onPrevious={handleLanguagePrevious} />
        </div>
      )}
      {showReferenceForm && !showTemplate && !showProfilePhoto && (
        <div>
          <ReferenceForm onNext={handleReferenceNext} onPrevious={handleReferencePrevious} />
        </div>
      )}
      {showProfilePhoto && !showTemplate && (
        <div>
          <ProfilePhotoUpload onNext={handleProfileNext} onPrevious={handlePhotoPrevious} />
        </div>
      )}

      <div>
        {showTemplate && (
          <div>
            <div style={{paddingLeft: '30px'}}>
              <button id="downloadButton" onClick={hideButtonAndPrint}>DOWNLOAD</button>
            </div>
            <div className="container1">
              <div className="topColor"></div>
              <div className="container2">
                <div className="leftSide">
                  <div className="leftSection1">
                    {profilePhoto && (
                      <div className="profileImg">
                        <img src={URL.createObjectURL(profilePhoto)} alt="Profile" className="pic" />
                      </div>
                    )}
                  </div>
                  <div className="infoContact">
                    {personalInfo && (
                      <div className="c-details">
                        {personalInfo.phone && (<div className="contact-list"><span className="iconPlace"><FaPhone aria-hidden="true" /></span><span className="info">{personalInfo.phone}</span></div>)}
                        {personalInfo.email && (<div className="contact-list"><span className="iconPlace"><MdMail aria-hidden="true" /></span><span className="info">{personalInfo.email}</span></div>)}
                        {personalInfo.github && (<div className="contact-list"><span className="iconPlace"><FaGithub aria-hidden="true" /></span><span className="info">{personalInfo.github}</span></div>)}
                        {personalInfo.linkedIn && (<div className="contact-list"><span className="iconPlace"><FaLinkedin aria-hidden="true" /></span><span className="info">{personalInfo.linkedIn}</span></div>)}
                        {personalInfo.location && (<div className="contact-list"><span className="iconPlace"><FaMapMarkerAlt aria-hidden="true" /></span><span className="info">{personalInfo.location}</span></div>)}
                      </div>
                    )}
                  </div>
                  <div className="infoContact">
                    <h2 className="leftHeading">Education</h2>
                    {education.map((education, index) => (
                      <div key={index}>
                        <div className="educationDetails">
                          <div className="eduStream">{education.courseName}{" in "}{education.stream}</div>
                          <div className="eduCollege">{education.collegeName}</div>
                          <div className="eduDate">{education.startDate}{"-"}{education.endDate}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="infoContact">
                    {professionalSkills.length > 0 && (
                      <div>
                        <h2 className="leftHeading">Expertise</h2>
                        {professionalSkills.map((professionalSkill, index) => (
                          <div key={index}>
                            <div className="professionalSkillName">{professionalSkill.name}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="infoContact">
                    <h2 className="leftHeading">Languages</h2>
                    {language.length > 0 && (
                      <div className="educationDetails">
                        {language.map((language, index) => (
                          <div key={index}>
                            <div className="languageInfo">{language.name}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="rightSide">
                  <div className="profileName">
                    {personalInfo && (
                      <div className="titleName">
                        <div className="userName">{personalInfo.fullName}</div>
                        <div className="userTitle">{personalInfo.title}</div>
                      </div>
                    )}
                  </div>
                  <div className="rightSummary">
                    <div className="headingProfile">Profile</div>
                    {personalInfo && (
                      <div className="profileDescribe">{personalInfo.description}</div>
                    )}
                  </div>
                  <div className="rightSummary">
                    {workExperiences.length > 0 && (
                      <div>
                        <div className="headingProfile">Work Experience</div>
                        {workExperiences.map((experience, index) => (
                          <div key={index}>
                            <div className="workAlign1">
                              <div className="part1">
                                <div className="workDate"> {experience.startDate}{" - "}{experience.endDate} </div>
                              </div>
                              <div className="part2">
                                <div className="remainInfo">
                                  <div className="company-name">{experience.companyName}</div>
                                  <div className="company-position">{experience.position}</div>
                                  <ul className="company-describe">
                                    {experience.jobDescription && <li>{experience.jobDescription}</li>}
                                    {experience.additionalField1 && <li>{experience.additionalField1}</li>}
                                    {experience.additionalField2 && <li>{experience.additionalField2}</li>}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="rightSummary">
                    {reference && (
                      <div>
                        <div className="headingProfile">Reference</div>
                        <div className="reference">
                          {reference.name && <div className="referenceName">{reference.name}</div>}
                          {reference.company && <div className="referenceCompany">{reference.company}</div>}
                          {reference.phoneNumber && <div className="referencePhoneNumber"><b>{"Phone: "}</b>{reference.phoneNumber}</div>}
                          {reference.companySite && <div className="referenceCompanySite"><b>{"Website: "}</b>{reference.companySite}</div>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Template3;