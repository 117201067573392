import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import DentalClinic from "./WebsiteAsset/DentalClinic.webp";
import EcommerceSite from "./WebsiteAsset/EcommerceSite.webp";
import FashionStore from "./WebsiteAsset/FashionStore.webp";
import InteriorDesign from "./WebsiteAsset/InteriorDesign.webp";
import RestaurantWebsite from "./WebsiteAsset/RestaurantWebsite.webp";
import "./StunningWebsite.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const StunningWebsite = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        pauseOnHover:true,
        initialSlide:0,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            }
          }
        ]
      };
  return (
    <div className='mainStunningContainer'>
        <h2 className='wStun-Line1'>Let us craft an stunning website for you and leave the competition amazed!</h2>
        <div className='wStun-Line2'>As a Trusted website designing company in the World, Techealer has earned its reputation for excellence. We don't just follow industry standards; we set them. Our commitment extends beyond making your website visually appealing; we ensure it's a high-performing, user-centric platform that sets you apart in the competitive digital landscape.</div>
      <Slider {...settings}>
      <div className='StunningContainer'>
        <LazyLoadImage src={DentalClinic} alt='dental-clinic'/>
        <div className='stun-image-text'>Dental Clinic</div>
      </div>
      <div className='StunningContainer'>
        <LazyLoadImage src={FashionStore} alt='fashion-store' />
        <div className='stun-image-text'>Fashion Store</div>
      </div>
      <div className='StunningContainer'>
        <LazyLoadImage src={EcommerceSite} alt='ecommerce-site' />
        <div className='stun-image-text'>E-Commerce Site</div>
      </div>
      <div className='StunningContainer'>
        <LazyLoadImage src={InteriorDesign} alt='interior-design' />
        <div className='stun-image-text'>Interior Design</div>
      </div>
      <div className='StunningContainer'>
        <LazyLoadImage src={RestaurantWebsite} alt='restaurant-website' />
        <div className='stun-image-text'>RestaurantWebsite</div>
      </div>
    </Slider>
    <div className='stunning-div1'>
        <div className='stun-left'>
            <div className='stun-content'>
                <div className='contentS-1'>Sell Anything Online</div>
                <div className='contentS-2'>We develop website that enable you to efficiently sell a wide range of products or services online.</div>
            </div>
        </div>
        <div className='stun-center'>
            <div className='stun-content'>
                <div className='contentS-1'>Boost online sales</div>
                <div className='contentS-2'>We carefully research and plan before creating your website, which lead to a large amount of boost in your sales.</div>
            </div>
        </div>
        <div className='stun-right'>
            <div className='stun-content'>
                <div className='contentS-1'>Credibility and Trust</div>
                <div className='contentS-2'>Our websites enhance online credibility, gain trust, and give you a competitive edge over your competition.</div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default StunningWebsite