import React, { useState, useEffect } from 'react';

function ProfessionalSkills({ onNext, onPrevious, professionalSkill }) {
  const initialProfessionalSkills = JSON.parse(sessionStorage.getItem('professionalSkills')) || [{ name: '', fluency: '' }];

  const [professionalSkills, setProfessionalSkills] = useState(professionalSkill || initialProfessionalSkills);

  useEffect(() => {
    sessionStorage.setItem('professionalSkills', JSON.stringify(professionalSkills));
  }, [professionalSkills]);

  const handleAddSkills = () => {
    setProfessionalSkills([...professionalSkills, { name: '', fluency: '' }]);
  };

  const handleRemoveSkills = (index) => {
    const updatedSkills = [...professionalSkills];
    updatedSkills.splice(index, 1);
    setProfessionalSkills(updatedSkills);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSkills = [...professionalSkills];
    updatedSkills[index][name] = value;
    setProfessionalSkills(updatedSkills);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(professionalSkills);
    window.scrollTo(0, 0);
  };

  return (
    <div className="form-container">
      <div className='headingTitle'>
        <h2 className='headingName'>Skill Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        {professionalSkills.map((professionalSkill, index) => (
          <div key={index}>
            <div className="input-group">
              <label htmlFor={`professionalSkillsName${index}`}>Skill Name:</label>
              <input
                type="text"
                id={`professionalSkillsName${index}`}
                name="name"
                value={professionalSkill.name}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter value [Ex:- JAVA/Python]"
                autoComplete='skills'
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor={`professionalSkillsFluency${index}`}>Skill Fluency:</label>
              <input
                type="text"
                id={`professionalSkillsFluency${index}`}
                name="fluency"
                value={professionalSkill.fluency}
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter fluency level from 1-9"
                autoComplete='frequencyLevel'
                required
              />
            </div>
            {index > 0 && (
              <button type="button" onClick={() => handleRemoveSkills(index)} className="removeButton">Remove</button>
            )}
          </div>
        ))}
        <div className="buttons">
          <button type="button" onClick={handleAddSkills}>Add Skill</button>
          <button type="button" onClick={onPrevious}>Previous</button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
}

export default ProfessionalSkills;
